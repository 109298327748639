import ServiceNSWTellUsOnce from '@snsw/tuo-js-client'
import { reduceNSWPointAddressProperties } from './utils'

class TuoClient {
    tuoJsClient = undefined
    isDisabled = false
    pointNswKey = process.env.REACT_APP_NSWPOINT_API_KEY

    constructor () {
        const tuoUrl = process.env.REACT_APP_TUO_URL
        const tuoEnv = process.env.REACT_APP_TUO_ENVIRONMENT

        if (!tuoUrl) {
            this.isDisabled = true
            return
        }
        const tuoClientConfig = { tellUsOnceUrl: tuoUrl, patchMode: false }
        if (tuoEnv) tuoClientConfig.env = tuoEnv
        this.tuoJsClient = new ServiceNSWTellUsOnce(tuoClientConfig)
    }

    getCustomer = async () => {
        if (this.isDisabled) return defaultCustomer
        try {
            const customer = await this.tuoJsClient.init()
            let { contactNumber, residentialAddress: address } = customer
            if (contactNumber.includes('+61')) contactNumber = contactNumber.replace('+61', '0').replace(/\s/g, '')
            address = await this.buildAddressForTransactionFrom(address)
            return { contactNumber, address }
        } catch {
            return defaultCustomer
        }
    }

    buildAddressForTransactionFrom = async (address) => {
        try {
            if (address.displayValue) {
                const addressRequest = encodeURI(address.displayValue)
                const matchingAddressListResponse = await this.callPointNSWAddressApi('/address-validation-predictive1?address=' + addressRequest)
                if (matchingAddressListResponse.status === 200) {
                    const matchingAddresses = await matchingAddressListResponse.json()
                    if (matchingAddresses.length === 1) {
                        const addressDetailsResponse = await this.buildAddressDetails(matchingAddresses[0].id)
                        return reduceNSWPointAddressProperties(addressDetailsResponse)
                    }
                }
            }
            return defaultAddress
        } catch {
            return defaultAddress
        }
    }

    buildAddressDetails = async (id) => {
        const addressValidationResponse2 = await this.callPointNSWAddressApi('/address-validation-predictive2?id=' + id)

        if (addressValidationResponse2.status === 200) {
            const addressDetails = await addressValidationResponse2.json()
            return addressDetails.data.properties
        }
        throw Error('Cannot find address details')
    }

    callPointNSWAddressApi = async (actionPath) => {
        return fetch(
            process.env.REACT_APP_NSWPOINT_API_BASE_URL +
            actionPath,
            {
                method: 'GET',
                headers: { 'x-api-key': this.pointNswKey }
            })
    }

    renderTuoSave = (id) => {
        if (this.isDisabled) return

        const currentData = window.sessionStorage.getItem('tuoCurrentData')
        const saveData = window.sessionStorage.getItem('tuoSavedData')
        if (currentData !== saveData) {
            this.tuoJsClient.renderReviewWidget(id)
        }
    }

    updateToSaveCustomer = ({ contactNumber, address }) => {
        if (this.isDisabled) return

        const newData = JSON.stringify({ contactNumber, address })
        if (window.location.href.includes('active-kids')) {
            window.sessionStorage.setItem('tuoSavedData', newData)
        } else {
            window.sessionStorage.setItem('tuoCurrentData', newData)
            this.renderTuoSave('tuo-review')
        }
    }

    setCustomer = async () => {
        if (this.isDisabled) return

        const saveData = window.sessionStorage.getItem('tuoSavedData')
        if (!saveData) return
        const { contactNumber, address } = JSON.parse(saveData)
        const newCustomer = {}
        if (contactNumber) {
            newCustomer.contactNumber = contactNumber
        }
        if (address && address.formattedAddressString !== '') {
            newCustomer.residentialAddress = buildAddressForTuoUpdate(address)
        }
        if (newCustomer !== {}) this.tuoJsClient.customer = newCustomer
    }

    renderTuoReview = (id) => {
        if (this.isDisabled) return
        this.tuoJsClient.renderReviewWidget(id)
    }
}

function buildAddressForTuoUpdate (transactionAddress) {
    const {
        unitNumber,
        stateTerritory,
        streetNumber,
        streetName,
        streetType,
        localityName,
        postcode
    } = transactionAddress
    const line1 = [unitNumber, streetNumber, streetName, streetType].filter(item => item).join(' ')

    return {
        line1,
        line2: '',
        suburb: localityName,
        postCode: postcode,
        stateCode: stateTerritory,
        countryCode: 'AU'
    }
}

const defaultAddress = {}

const defaultCustomer = {
    contactNumber: '',
    address: defaultAddress
}

export default new TuoClient()
