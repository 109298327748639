import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../components/Modal'
import StyledCheckbox from '../../components/StyledCheckbox'

class HeightAndWeightInfoModal extends Component {
    constructor (props) {
        super(props)
        this.state = {
            preferNotToSay: this.props.preferNotToSay
        }
    }

    handleCheckboxChange = () => {
        this.setState({ preferNotToSay: !this.state.preferNotToSay })
    }

    handleContinue = () => {
        if (this.state.preferNotToSay !== this.props.preferNotToSay) {
            this.props.changePreferNotToSay(this.state.preferNotToSay)
        }
        this.props.closeModal()
    }

    render () {
        const { closeModal } = this.props
        return <Modal title="Why your child’s height and weight are important to us"
            closeModal={closeModal}
            body={<div>
                <p>
                  This data is provided to the University of Sydney to conduct research to assess how
                  programs such as Active Kids help to improve the health of our community.
                </p>
                <p>
                  The data you provide is confidential and will not be linked to you or your child’s name,
                  nor will it affect your eligibility to obtain an Active Kids voucher. It will simply be
                  used to measure the effectiveness of the program as a whole.
                </p>
                <p>
                  If you do not wish to provide this data, you may opt out below.
                </p>
                <div className="columns" style={{ paddingLeft: 0, marginTop: '2rem', marginBottom: '2rem' }}>
                    <div className='column is-narrow'>
                        <StyledCheckbox name='height-weight-prefer-not-to-say' checked={this.state.preferNotToSay} onChange={this.handleCheckboxChange}/>
                    </div>
                    <div className="column">
                        <label htmlFor="height-weight-prefer-not-to-say">
                            I do not wish to provide my child’s height and weight
                        </label>
                    </div>
                </div>
                <div className="control" style={{ paddingBottom: '2rem' }}>
                    <div className="button is-primary" onClick={this.handleContinue}>Continue</div>
                </div>
            </div>
            }
        />
    }
}

HeightAndWeightInfoModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    preferNotToSay: PropTypes.bool.isRequired,
    changePreferNotToSay: PropTypes.func.isRequired
}

export default HeightAndWeightInfoModal
