const isCreativeEnabled = !!process.env.REACT_APP_CK_ENABLED

export const ActiveKidsVoucherTypeDetails = {
    code: 'AK',
    basePath: 'active-kids',
    name: 'Active Kids',
    tutdCode: 'Active Kids 3',
    providerSearch: 'ActiveKidsSearchProvider',
    agencyName: 'Office of Sport',
    poiAgencyCode: 'VOUCHERS-AK-PARENT',
    termsAndConditionsUri: 'https://sport.nsw.gov.au/active-kids-recipient-TandC',
    poiTransactionId: '0g45dfg-345dgdfg',
    privacyUrl: 'https://www.sport.nsw.gov.au/privacy'
}

export const CreativeKidsVoucherTypeDetails = {
    code: 'CK',
    basePath: 'creative-kids',
    name: 'Creative Kids',
    tutdCode: 'Creative Kids 2',
    providerSearch: 'CreativeKidsSearchProvider',
    agencyName: 'Create NSW',
    poiAgencyCode: 'VOUCHERS-CK-PARENT',
    termsAndConditionsUri: 'https://www.service.nsw.gov.au/creative-kids-recipient-terms-and-conditions',
    privacyUrl: 'https://www.service.nsw.gov.au/creative-kids-program-privacy-statement'
}

export const ActiveKidsPath = ActiveKidsVoucherTypeDetails.basePath
export const CreativeKidsPath = isCreativeEnabled ? CreativeKidsVoucherTypeDetails.basePath : 'error'

export const VoucherTypeAK = ActiveKidsVoucherTypeDetails.code
export const VoucherTypeCK = CreativeKidsVoucherTypeDetails.code

export const getVoucherTypeDetails = (voucherType) => {
    switch (voucherType) {
    case VoucherTypeCK:
        return CreativeKidsVoucherTypeDetails
    case VoucherTypeAK:
        return ActiveKidsVoucherTypeDetails
    default:
        return null
    }
}

export const getVoucherTypeFromPath = (pathname) => {
    const isCreative = getPathBase(pathname) === CreativeKidsVoucherTypeDetails.basePath
    if (isCreative && isCreativeEnabled) return CreativeKidsVoucherTypeDetails.code
    else if (isCreative) {
        console.error('creative kids is disabled')
        return 'error'
    } else return ActiveKidsVoucherTypeDetails.code
}

export const getPathBase = (pathname) => {
    const pathBase = pathname.replace(process.env.PUBLIC_URL, '').split('/')[1]
    return pathBase
}

export const getPathBaseFromVoucherType = (voucherType) => {
    switch (voucherType) {
    case VoucherTypeCK:
        return (isCreativeEnabled ? CreativeKidsPath : 'error')
    case VoucherTypeAK:
        return ActiveKidsPath
    default:
        return null
    }
}
