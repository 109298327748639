import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputWithLabelAndErrorMessage from '../../components/InputWithLabelAndErrorMessage'
import SchoolSelector from '../../components/SchoolSelector'

class ResearchQuestionCorrectionForm extends Component {
    constructor (props) {
        super(props)
        this.state = {
            formValues: {},
            postcodeInvalid: false
        }
    }

    handlePostcodeChange = (event) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                postcode: event.target.value
            }
        })
    }

    handleSchoolChange = (selectedSchool) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                school: selectedSchool ? selectedSchool.name : null,
                schoolSuburb: selectedSchool ? selectedSchool.suburb : null
            }
        })
    }

    componentDidUpdate (prevProps, prevState) {
        if (JSON.stringify(this.state.formValues) !== JSON.stringify(prevState.formValues)) {
            this.submitValues()
        }
    }

    submitValues = () =>
        this.props.onChange({
            isValid: this.isFormValid(),
            school: this.state.formValues.school,
            schoolSuburb: this.state.formValues.schoolSuburb,
            residentialPostCode: this.state.formValues.postcode
        })

    isFormValid = () =>
        !!(this.state.formValues.school && this.isPostcodeValid())

    isPostcodeValid = () => this.state.formValues.postcode &&
        !isNaN(this.state.formValues.postcode) &&
        this.state.formValues.postcode.length === 4

    isFieldEmpty = (field) => this.props.didSubmit && this.props?.errors[field] && !this.state.formValues[field]
    isPostCodeEmpty = () => this.props.didSubmit && this.props?.errors?.residentialPostCode && !this.state.formValues?.postcode

    render () {
        return <div className="correction-questions" style={this.props.hidden ? { display: 'none' } : { display: 'block' }}>
            <div className="research-question" ref={this.props.refs?.school}>
                <label className="label">School</label>
                <SchoolSelector id="school" onChange={this.handleSchoolChange} isError={this.isFieldEmpty('school')}/>
                {this.isFieldEmpty('school') &&
                    <p className="empty-error help is-danger">{this.props.errors.school ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.residentialPostCode}>
                <InputWithLabelAndErrorMessage
                    className={(this.isPostCodeEmpty() ? ' is-danger' : '')}
                    name="postcode"
                    label="Residential postcode"
                    type="text"
                    errorMessage="Please enter a valid NSW postal code"
                    showErrorMessage={!!(this.state.formValues.postcode && !this.isPostcodeValid())}
                    onChange={this.handlePostcodeChange}/>
                {this.props.didSubmit && this.props?.errors.residentialPostCode && !this.state.formValues.postcode &&
                    <p className="empty-error help is-danger">{this.props.errors?.residentialPostCode ?? ''}</p>}
            </div>
        </div>
    }
}

ResearchQuestionCorrectionForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
    didSubmit: PropTypes.bool,
    refs: PropTypes.any,
    errors: PropTypes.object
}

export default ResearchQuestionCorrectionForm
