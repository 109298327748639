import AuthClient from '../components/AuthClient'

export default {
    getRedirectURL: async (voucherType) => {
        const response = await fetch(process.env.REACT_APP_BACKEND_API + '/user/poi/redirect', {
            method: 'GET',
            headers: AuthClient.appendAuthHeaders({ 'X-AGENCY-CODE': voucherType.poiAgencyCode })
        })
        if (response.status === 200) {
            return response.text()
        } else {
            throw new Error('Redirect error')
        }
    },
    getPoiStatus: async (voucherType) => {
        const response = await fetch(process.env.REACT_APP_BACKEND_API + '/user/poi/status', {
            method: 'GET',
            headers: AuthClient.appendAuthHeaders({
                'X-AGENCY-CODE': voucherType.poiAgencyCode
            })
        })
        if (response.status === 200) {
            const status = await response.json()
            return status
        } else {
            return null
        }
    },
    getParentDetails: async (voucherType) => {
        const response = await fetch(process.env.REACT_APP_BACKEND_API + '/user/me/details/' + voucherType, {
            method: 'GET',
            headers: AuthClient.appendAuthHeaders({})
        })
        if (response.status === 200) {
            const status = await response.json()
            return status
        } else {
            return null
        }
    }
}
