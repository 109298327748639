import React from 'react'
import PropTypes from 'prop-types'
import Section from '../layout/ProgressSection'
import { connect } from 'unistore/react'
import './VoucherDetailsPage.scss'
import ThumbsUpThumbsDown from '../components/ThumbsUpThumbsDown'
import ActionButton from '../components/ActionButton'
import VouchersApi from '../research-data/VouchersApi'
import PageComponent from '../components/PageComponent'
import OtherProducts from '../components/OtherProducts'
import VoucherCard from './VoucherCard'
import Notification from '../components/Notification'
import tuoClient from '../utils/tuoClient'
import ReactGA from 'react-ga'
import { getVoucherTypeDetails, getVoucherTypeFromPath, VoucherTypeCK } from '../VoucherTypeDetails'
import CreativeVoucherUsagePanel from './CreativeVoucherUsagePanel'
import AuthClient from '../components/AuthClient'

export class VoucherDetailsPage extends PageComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        transactionContext: PropTypes.object.isRequired,
        parent: PropTypes.object.isRequired
    }

    constructor (props) {
        super(props)
        this.state = {
            additionalEmailAddress: '',
            isSubmitting: false,
            emailSendSuccess: false,
            voucherType: getVoucherTypeFromPath(this.props.location.pathname),
            correlationId: AuthClient.getCorrelationId()
        }
        AuthClient.clearCorrelationId()
    }

    recordPageView = (name) => {
        ReactGA.set({ page: `${this.props.location.pathname}/${name}` })
        ReactGA.pageview(`${this.props.location.pathname}/${name}`)
    }

    componentDidMount () {
        // we need to call the parent function also because we are overriding the function
        super.componentDidMount()
        tuoClient.renderTuoReview('tuo-review')
    }

    handleInput = name => (event) => {
        this.setState({
            [name]: event.target.value
        })
    }

    isValidEmail = (emailAddress) => {
        return emailAddress.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/igm)
    }

    handleSend = () => {
        this.recordPageView('resend')
        this.setState({ isSubmitting: true, emailSendSuccess: false })
        const { additionalEmailAddress } = this.state
        const promises = this.props.transactionContext.vouchers
            .map(voucher => {
                if (!!voucher.error === false) {
                    return VouchersApi.send(voucher.voucherCode, additionalEmailAddress)
                }
                return null
            })
        Promise.all(promises).then(() => {
            this.setState({ isSubmitting: false, emailSendSuccess: true, additionalEmailAddress: '' })
        })
    }

    handleProviderSearchClick = () => {
        this.recordPageView('provider-search')
        return true
    }

    handleDashboardClick = () => {
        this.recordPageView('dashboard')
        return true
    }

    makeCustomerReference = (correlationId) => {
        return 'VOU-' + correlationId?.substr(correlationId.length - 8) + '-' + this.state.voucherType + 'V'
    }

    render () {
        const { email } = this.props.parent
        const voucherTypeDetails = getVoucherTypeDetails(this.state.voucherType)
        return <Section className="VoucherDetailsPage columns">
            { this.state.correlationId && <div id='MobileAppConfirmationPageFlag' data-customer-reference={ this.makeCustomerReference(this.state.correlationId) } />}
            <div className=" is-8-desktop column">
                <h1 className="title is-1 no-print">Your vouchers are ready</h1>
                { this.props.transactionContext.vouchers.map((voucher, index) => voucher.error &&
                    <Notification key={index} type="danger" message={
                        <div>
                            <b>Unable to create voucher</b><br/>
                            Unfortunately we were unable to create a voucher for {voucher.nameOnVoucher}.
                            To create a voucher for {voucher.nameOnVoucher},  you will need to try again by completing a separate
                            application. <br/> For all other vouchers, please
                            see below.
                        </div>}/>)
                }
                <div className="email-message no-print">
                    <b>We’ve emailed your vouchers to {email}. They are also available in
                    your <a target="_blank"
                        style={{ textDecoration: 'underline' }}
                        rel="noopener noreferrer"
                        onClick={this.handleDashboardClick}
                        href={`${process.env.REACT_APP_SALES_FORCE_FLOW_ENV_ROOT}/PersonalDashboard`}>MyServiceNSW Account</a> under ‘My Services’, or the <a target="_blank"
                        style={{ textDecoration: 'underline' }}
                        rel="noopener noreferrer"
                        href="https://www.service.nsw.gov.au/service-nsw-mobile-app">Service NSW Mobile App</a>.</b>
                </div>
                <div className="print-vouchers-section">
                    <button className="button is-primary" onClick={() => window.print()}>Print vouchers</button>
                </div>
                <div className="find-providers-section">
                    <p>
                        <b>You can now use your vouchers. </b>
                        If you don’t already have a provider in mind, search
                        thousands of {voucherTypeDetails.name} providers
                        using our search tool.
                        Note that some providers only accept printed vouchers.
                    </p>
                    <a className="button is-secondary" target="_blank" rel='noreferrer noopener' onClick={this.handleProviderSearchClick}
                        href={`${process.env.REACT_APP_SALES_FORCE_FLOW_ENV_ROOT}/${voucherTypeDetails.providerSearch}`}>Find a provider</a>
                </div>

                <div className="no-print">
                    <ThumbsUpThumbsDown label={`Do you like the ${voucherTypeDetails.name} program?`} sourceApplication={voucherTypeDetails.tutdCode}/>
                </div>
                { this.props.transactionContext.vouchers.map(voucher => (!!voucher.error === false) && <VoucherCard key={voucher.voucherCode} voucher={voucher}/>) }

                {this.state.voucherType === VoucherTypeCK && <CreativeVoucherUsagePanel/>}

                <div className="additional-email-section">
                    <label className="label is-size-6">Send vouchers to a different email address</label>
                    <input name="additionalEmailAddress"
                        className={`input ${this.state.additionalEmailAddress && !this.isValidEmail(this.state.additionalEmailAddress) ? ' is-danger' : ''}`}
                        type="email"
                        value={this.state.additionalEmailAddress}
                        onChange={this.handleInput('additionalEmailAddress')}
                    />
                    {this.state.additionalEmailAddress && !this.isValidEmail(this.state.additionalEmailAddress) &&
                      <p className="email-error help is-danger">
                        Please enter a valid email address
                      </p>
                    }
                    {this.state.emailSendSuccess && <p className="help is-success">
                        Successfully sent email.
                    </p>}
                    {this.isValidEmail(this.state.additionalEmailAddress) &&
                    <ActionButton
                        className="is-secondary"
                        processing={this.state.isSubmitting}
                        onClick={this.handleSend} text="Send"
                    />}
                </div>
            </div>
            <div className="no-print">
                <div id="tuo-review"/>
                <OtherProducts voucherType={this.state.voucherType}/>
            </div>
        </Section>
    }
}

export default connect('parent,transactionContext')(VoucherDetailsPage)
