export const reduceNSWPointAddressProperties = (addressDetails) => {
    if (addressDetails) {
        if (!addressDetails.unitNumber) {
            addressDetails.unitNumber = addressDetails.siteName ? addressDetails.siteName + ' ' : ''
            if (addressDetails.complexLevelType) {
                addressDetails.unitNumber += addressDetails.complexLevelType + ' ' + addressDetails.complexLevelNumber + ' '
            }
            if (addressDetails.complexUnitIdentifier) {
                addressDetails.unitNumber += addressDetails.complex_unit_type_description
                    ? addressDetails.complex_unit_type_description + ' ' : ''
                addressDetails.unitNumber += addressDetails.complexUnitIdentifier
            }
        }
        if (!addressDetails.streetNumber) {
            if (addressDetails.streetNumber1) {
                addressDetails.streetNumber = addressDetails.streetNumber1
            }
            if (addressDetails.streetNumber2) {
                addressDetails.streetNumber += '-' + addressDetails.streetNumber2
            }
            if (addressDetails.lotIdentifier) {
                addressDetails.streetNumber += ' LOT ' + addressDetails.lotIdentifier
                addressDetails.streetNumber = addressDetails.streetNumber.trim()
            }
        }
        addressDetails.dpid = addressDetails.cadastralIdentifier
        addressDetails.formattedAddressString = addressDetails.formattedAddress
        addressDetails.stateTerritory = addressDetails.state
    }

    return addressDetails
}
