import React from 'react'
import PropTypes from 'prop-types'
import Notification from '../components/Notification'
import { VoucherTypeAK, VoucherTypeCK } from '../VoucherTypeDetails'
// import moment from 'moment'

const ChildEligibilityError = ({ checkResult }) => {
    return <Notification type="danger" message={getMessage(checkResult)}/>
}

const systemErrorMessage = 'Sorry, due to a system error, we can\'t verify your Medicare card at this time. Please try again later.'
const loginErrorMessage = 'You are not logged in. Perhaps your login has expired. Please log in using the link at the top of this page.'
const invalidMedicareMessage = 'The details you entered do not match our records. ' +
    'Please check your details are correct and try again.'
const invalidMedicareDMessage = 'Your Medicare details are correct, ' +
    'though due to a problem with your Medicare card we are unable to verify it. ' +
    'Please call Medicare to resolve this issue.'

const ckBothVouchersExistMessage = <span>
    <span className="has-text-weight-bold">We cannot progress your application</span><br/>
        It looks like this child has already claimed their $50 Creative Kids voucher.
</span>

const akBothVouchersExistMessage = <span>
    <span className="has-text-weight-bold">We cannot progress your application</span><br/>
        It looks like this child has already claimed their $50 Active Kids voucher.
</span>

const firstVoucherExistMessage = <span>
    Sorry, this child isn’t able to claim this voucher because it appears this child has already received
    their eligible voucher.
    For more information on the vouchers your child has claimed, please
    visit <a href={`${process.env.REACT_APP_SALES_FORCE_FLOW_ENV_ROOT}/PersonalDashboard`}>My applications</a> on
    your MyServiceNSW Dashboard.
</span>
const ckVoucherExistMessage = <span>
    Sorry, this child has already claimed their Creative Kids voucher this year.
    But don’t worry, you may try again on the 1st of January when they will be eligible for a new voucher.
    For more information on the vouchers your child has claimed, please
    visit <a href={`${process.env.REACT_APP_SALES_FORCE_FLOW_ENV_ROOT}/PersonalDashboard`}>My applications</a> on
    your MyServiceNSW Dashboard.
</span>
const ckVoucherAlreadyRedeemedMessage = <span>
    <span className="has-text-weight-bold">We cannot progress your application</span><br/>
    This child has already redeemed the $100 Creative Kids voucher issued earlier year.{'  '}
    <a href={'https://www.service.nsw.gov.au/transaction/apply-for-a-creative-kids-voucher#eligibility'}>Check the eligibility criteria.</a>
</span>
const childAlreadyExists = <span>
    <span className="has-text-weight-bold">Child already exists</span><br/>
    The child you have entered already exists in this application. If you would like to
add another child, please update the fields above or select ‘Continue’ to go to the
next step.
</span>

function getMessage (checkResult) {
    // let isSecondHalfOfYear = false
    // try {
    //     const currentMonth = moment().month() + 1
    //     if (process.env.REACT_APP_SECOND_HALF_YEAR_MONTH && Number(process.env.REACT_APP_SECOND_HALF_YEAR_MONTH) &&
    //         currentMonth >= Number(process.env.REACT_APP_SECOND_HALF_YEAR_MONTH)) {
    //         isSecondHalfOfYear = true
    //     }
    // } catch (e) {
    //     console.error(e)
    // }
    if (checkResult.status === 'INVALID_MEDICARE') {
        return invalidMedicareMessage
    } else if (checkResult.status === 'INVALID_MEDICARE_D') {
        return invalidMedicareDMessage
    } else if (checkResult.status === 'VOUCHER_EXISTS' && checkResult.voucherType === VoucherTypeCK) {
        return ckBothVouchersExistMessage
    } else if (checkResult.status === 'VOUCHER_EXISTS' && checkResult.voucherType === VoucherTypeAK) {
        return akBothVouchersExistMessage
    } else if (checkResult.status === 'VOUCHER_EXISTS' && checkResult.voucherType === VoucherTypeCK && checkResult.numberOfIssuedVouchers === 1) {
        return ckVoucherExistMessage
    } else if (checkResult.status === 'VOUCHER_ALREADY_REDEEMED' && checkResult.voucherType === VoucherTypeCK) {
        return ckVoucherAlreadyRedeemedMessage
    } else if (checkResult.status === 'VOUCHER_EXISTS' && checkResult.voucherType === VoucherTypeAK && checkResult.numberOfIssuedVouchers === 1) {
        return firstVoucherExistMessage
    } else if (checkResult.status === 'VOUCHER_EXISTS' && checkResult.voucherType === VoucherTypeCK && checkResult.numberOfIssuedVouchers === 2) {
        return ckBothVouchersExistMessage
    } else if (checkResult.status === 'VOUCHER_EXISTS' && checkResult.voucherType === VoucherTypeAK && checkResult.numberOfIssuedVouchers === 2) {
        return akBothVouchersExistMessage
    } else if (checkResult.status === 'ERROR') {
        if (checkResult.errorCode === 401 || checkResult.errorCode === 403) {
            return loginErrorMessage
        } else if (checkResult.errorCode === 400) {
            return invalidMedicareMessage
        }
    } else if (checkResult.status === 'ERROR_ALREADY_ADDED') {
        return childAlreadyExists
    }

    return systemErrorMessage
}

ChildEligibilityError.propTypes = {
    checkResult: PropTypes.object.isRequired
}

export default ChildEligibilityError
