import React from 'react'
import PropTypes from 'prop-types'
import './StyledCheckbox.scss'

const StyledCheckbox = (props) =>
    <div className={`StyledCheckbox ${props.name}`}>
        <input
            id={props.name}
            name={props.name}
            className="checkbox"
            type="checkbox"
            onChange={props.onChange}
            checked={props.checked}
        />
        <label htmlFor={props.name}/>
    </div>

StyledCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

export default StyledCheckbox
