const actions = (store) => ({
    setChildren: (state, children) => ({
        ...state,
        transactionContext: {
            children: children,
            vouchers: []
        }
    }),

    clearChildren: (state) => ({
        ...state,
        transactionContext: {
            ...state.transactionContext,
            children: []
        }
    }),

    setParentData: (state, parent) => ({
        ...state,
        parent: {
            ...state.parent,
            ...parent
        }
    }),

    addVoucher: (state, voucher) => ({
        ...state,
        transactionContext: {
            ...state.transactionContext,
            vouchers: state.transactionContext.vouchers.concat([voucher])
        }
    })
})
export default actions
