import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../components/Modal'

function PhysicallyActiveInfoModal (props) {
    const { closeModal } = props
    return <Modal title="What activities are considered ‘physically active’?"
        closeModal={closeModal}
        body={<div>
            <p>
              Any activity involving movement that raises an individuals heart rate,
              which can include walking, cycling to school, sport at lunchtime, or an
              exercise class.
            </p>
        </div>
        }
    />
}

PhysicallyActiveInfoModal.propTypes = {
    closeModal: PropTypes.func.isRequired
}

export default PhysicallyActiveInfoModal
