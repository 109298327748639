import AuthClient from '../components/AuthClient'

const submitResearchData = (data, method, url) => {
    return fetch(process.env.REACT_APP_BACKEND_API + url, {
        method: method,
        body: JSON.stringify(data),
        headers: AuthClient.appendAuthHeaders({
            'Content-Type': 'application/json'
        })
    })
}

export default {
    submit: (data, voucherType) => submitResearchData(data, 'POST', `/research-data${voucherType === 'CK' ? '/ck' : ''}`),
    submitCorrections: data => submitResearchData(data, 'PATCH', '/research-data')
}
