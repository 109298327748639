import React from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/display-name
const InputWithLabelAndErrorMessage = (props) => {
    const getErrorMessage = () => {
        if (Array.isArray(props.errorMessage)) {
            return props.errorMessage.map((v, i) => {
                return <div key={`${props.name}-error-${i}`} className={`${props.name} help is-danger form__error is-size-6`}>{v}</div>
            })
        }
        return <div className={`${props.name} help is-danger form__error is-size-6`}>{props.errorMessage}</div>
    }
    return <div>
        <label className="label" htmlFor={props.name}>{props.label}</label>
        {props.sublabel && <div className={props.sublabelClassname ? props.sublabelClassname : 'is-italic is-size-7 label-description'}>{props.sublabel}</div>}
        <input className={`input ${props.showErrorMessage && 'is-danger'} ${props.className ?? ''}`}
            name={props.name}
            value={props.value}
            disabled={props.disabled || false}
            type={props.fieldType || 'text'}
            onChange={props.onChange}
            onBlur={props.onBlur}/>
        {props.showErrorMessage && getErrorMessage()}
    </div>
}

InputWithLabelAndErrorMessage.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    fieldType: PropTypes.string,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    showErrorMessage: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    sublabel: PropTypes.string,
    sublabelClassname: PropTypes.string,
    className: PropTypes.string
}

export default InputWithLabelAndErrorMessage
