import './App.scss'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ChildEligibilityPage from './child-eligibility/ChildEligibilityPage'
import ResearchDataPage from './research-data/ResearchDataPage'
import VoucherDetailsPage from './voucher-details/VoucherDetailsPage'
import ErrorPage from './components/ErrorPage'
import ProtectedRoute from './ProtectedRoute'
import { Provider } from 'unistore/react'
import { store } from './store'
import ContactDetailsPage from './contact-details/ContactDetailsPage'
import AuthCallbackPage from './components/AuthCallbackPage'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import LoginPage from './contact-details/LoginPage'
import {
    ActiveKidsPath,
    CreativeKidsPath,
    getVoucherTypeFromPath,
    VoucherTypeAK,
    VoucherTypeCK
} from './VoucherTypeDetails'

const history = createBrowserHistory({ basename: process.env.PUBLIC_URL || '' })

// initialise GA page view tracking
history.listen(location => {
    onPageView(location.pathname)
})

const onPageView = (pathname) => {
    ReactGA.set({ page: pathname })
    ReactGA.pageview(pathname)
    switch (getVoucherTypeFromPath(pathname)) {
    case VoucherTypeAK:
        document.title = 'Active Kids - Service NSW'
        break
    case VoucherTypeCK:
        document.title = 'Creative Kids - Service NSW'
        break
    default:
        document.title = 'Vouchers - Service NSW'
    }
}

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
ReactGA.set({
    env: process.env.REACT_APP_TUTD_ENV
})
onPageView(window.location.pathname)

const App = () => {
    return (
        <BrowserRouter history={history} basename={process.env.PUBLIC_URL || ''}>
            <AppProvider/>
        </BrowserRouter>
    )
}

export const AppProvider = () => {
    return <Provider store={store}>
        <div className="app">
            <Switch>
                <Route path={'/error'} component={ErrorPage}/>
                <Route path={'/callback'} component={AuthCallbackPage}/>
                <Route path={`/${ActiveKidsPath}/claim`} component={LoginPage}/>
                <Route path={`/${ActiveKidsPath}/login-callback`} component={AuthCallbackPage}/>
                <Route path={`/${ActiveKidsPath}/error`} component={ErrorPage}/>
                <Route path={`/${ActiveKidsPath}/parent`} component={ContactDetailsPage}/>
                <ProtectedRoute path={`/${ActiveKidsPath}/child-eligibility`} component={ChildEligibilityPage}/>
                <ProtectedRoute path={`/${ActiveKidsPath}/research-data`} component={ResearchDataPage}/>
                <ProtectedRoute path={`/${ActiveKidsPath}/confirmation`} component={VoucherDetailsPage}/>
                <Route path={`/${CreativeKidsPath}/claim`} component={LoginPage}/>
                <Route path={`/${CreativeKidsPath}/login-callback`} component={AuthCallbackPage}/>
                <Route path={`/${CreativeKidsPath}/error`} component={ErrorPage}/>
                <Route path={`/${CreativeKidsPath}/parent`} component={ContactDetailsPage}/>
                <ProtectedRoute path={`/${CreativeKidsPath}/child-eligibility`} component={ChildEligibilityPage}/>
                <ProtectedRoute path={`/${CreativeKidsPath}/research-data`} component={ResearchDataPage}/>
                <ProtectedRoute path={`/${CreativeKidsPath}/confirmation`} component={VoucherDetailsPage}/>
                <Route component={ErrorPage} />
            </Switch>
        </div>
    </Provider>
}

export default App
