import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NSWStateLogo from '../icons/nsw-waratah.svg'
import ActiveKidsLogo from '../icons/activekids-dark-no-nsw.svg'
import CreativeKidsLogo from '../icons/CreativeKids_logo.svg'
import ActiveKidsVoucherImage1 from './voucher-ak-image1.png'
import ActiveKidsVoucherImage2 from './voucher-ak-image2.png'
import CreativeKidsVoucherImage from './voucher-ck-image.png'
import moment from 'moment'
import './VoucherCard.scss'
import { VoucherTypeAK, VoucherTypeCK, getVoucherTypeDetails } from '../VoucherTypeDetails'

class VoucherCard extends Component {
    formatVoucher = (voucherCode) => {
        return voucherCode.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ').trim()
    }

    formatValue = (value) => {
        return '$' + value
    }

    render () {
        const { voucher } = this.props
        const voucherTypeName = getVoucherTypeDetails(voucher.voucherType).name
        const isAK = voucher.voucherType === VoucherTypeAK
        const isCK = voucher.voucherType === VoucherTypeCK
        return <div className={`card voucher-card voucher-type-${voucher.voucherType.toLowerCase()}`}>
            <p className={`card-header-title is-left ${(voucher.voucherType === VoucherTypeAK && voucher.issue === 2 ? ' second-voucher' : '')}`}>
                {voucherTypeName} Voucher{isAK && ' ' + voucher.issue}
            </p>
            <div className="voucher-content">
                <div className="logos">
                    <img alt="NSW State Logo" src={NSWStateLogo}/>
                    {isAK && <img alt={`${voucherTypeName} Logo`} src={ActiveKidsLogo}/>}
                    {isCK && <img alt={`${voucherTypeName} Logo`} src={CreativeKidsLogo} className="creative-kids-logo"/>}
                </div>

                {isAK &&
                    <img src={voucher.issue === 1 ? ActiveKidsVoucherImage1 : ActiveKidsVoucherImage2}
                        alt="active kids involved in a sporting activity" className="background-image"/>}
                {isCK &&
                <img src={CreativeKidsVoucherImage}
                    alt="kids involved in a creative activity" className="background-image"/>}
                <label className="label">Voucher number</label>
                <div className="data-item voucher-code is-size-1">
                    {this.formatVoucher(voucher.voucherCode)}
                </div>

                <label className="label">Name</label>
                <div className="name data-item is-size-3">{voucher.nameOnVoucher}</div>

                <div className="columns is-mobile">
                    <div className="column is-half">
                        <label className="label">Expiry date</label>
                        <div className="data-item expiry-date is-size-5">
                            {moment(voucher.expiryDate).format('DD MMMM YYYY')}
                        </div>
                    </div>
                    <div className="column is-half">
                        <label className="label">Value</label>
                        <div className="data-item value is-size-5">
                            {this.formatValue(voucher.value)}
                        </div>
                    </div>
                </div>

                {voucher.voucherType === VoucherTypeCK &&
                    <p className={'dob-note'}> Please remember to give your child’s date of birth to the Provider with the voucher.</p>
                }
                <p className="small-print">
                    Each {voucherTypeName} voucher can only be used for the named person and can only be used once.
                    This voucher can only be redeemed with a registered {voucherTypeName} provider.
                    If the membership and/or participation fees are less than {this.formatValue(voucher.value)} for an activity, then the
                    balance will be forfeited.
                    The balance is not redeemable in cash or towards the membership and/or participation fees
                    for another activity.
                </p>

            </div>
            {voucher.voucherType === VoucherTypeCK &&
            <div className="card-footer">
                Image: Shopfront Junior Ensemble The Pecking Order, © Tim da-Rin
            </div>}
        </div>
    }
}

VoucherCard.propTypes = {
    voucher: PropTypes.object.isRequired
}

export default VoucherCard
