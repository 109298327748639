import React from 'react'
import PropTypes from 'prop-types'
import CheckMark from '../icons/status-success.svg'
import './ResearchDataInfoBox.scss'

function ResearchDataInfoBox (props) {
    const { isValid, isSecondVoucher, needsResearchDataUpdates, childName } = props
    const showTick = isValid || (isSecondVoucher && !needsResearchDataUpdates)
    return <div className={'research-info-box is-size-5 columns' + ((isValid || (isSecondVoucher && !needsResearchDataUpdates)) ? ' is-valid' : '')}>
        <div className="column is-one-third">{ childName }</div>
        {showTick && <img src={CheckMark} alt="tick" className="column tick"/>}
        {!isSecondVoucher && !isValid && <div className="column is-half">Basic information needs to be entered</div>}
        {!isSecondVoucher && isValid && <div className="column is-half">Basic information has been entered</div>}
        {(isSecondVoucher && !needsResearchDataUpdates) && <div className="column is-half">Basic information already collected for this year</div>}
        {(isSecondVoucher && needsResearchDataUpdates) && <div className="column is-half">Basic information needs to be updated</div>}
    </div>
}

ResearchDataInfoBox.propTypes = {
    isValid: PropTypes.bool.isRequired,
    isSecondVoucher: PropTypes.bool.isRequired,
    needsResearchDataUpdates: PropTypes.bool.isRequired,
    childName: PropTypes.string.isRequired
}

export default ResearchDataInfoBox
