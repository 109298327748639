import AuthClient from '../components/AuthClient'

export default {
    check: identityData => fetch(process.env.REACT_APP_BACKEND_API + '/child/identity/check', {
        method: 'POST',
        body: JSON.stringify(identityData),
        headers: AuthClient.appendAuthHeaders({
            'Content-Type': 'application/json'
        })
    }).then(response => response.ok ? response.json() : { status: 'ERROR', errorCode: response.status, errorText: response.statusText })
}
