import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types'
import { SchoolApi } from './SchoolApi'
import { debounce } from 'throttle-debounce'

class SchoolSelector extends Component {
    constructor (props, context) {
        super(props, context)
        this.state = { cleared: false }
        this.schoolOptions = debounce(500, this.findSchoolOptions)
    }

    schoolOptionLabel = option => option.name + (option.suburb ? ', ' + option.suburb : '')

    addHomeSchooling = response => {
        response.push({ name: 'Home Schooled', suburb: '' })
        response.push({ name: 'School not listed', suburb: '' })
        response.push({ name: 'TAFE NSW', suburb: '' })
        return response
    }

    findSchoolOptions = (inputValue, callback) => {
        inputValue
            ? SchoolApi.findSchools(inputValue)
                .then(response => callback(this.addHomeSchooling(response)))
                .catch((error) => {
                    console.log(error, 'error in findSchools')
                })
            : SchoolApi.getAllSchools()
                .then(response => callback(this.addHomeSchooling(response)))
                .catch((error) => {
                    console.log(error, 'error in getAllSchools')
                })
    }

    handleChange = (event) => {
        this.setState({ cleared: true })
        this.props.onChange(event)
    }

    usePlaceHolder = () => (this.state.cleared || !this.props.defaultValue)

    render () {
        const customPlaceholderStyle = {
            placeholder: (defaultStyles) => {
                return {
                    ...defaultStyles,
                    color: 'black'
                }
            }
        }

        const defaultPlaceholderStyle = {
            placeholder: (defaultStyles) => {
                return {
                    ...defaultStyles
                }
            }
        }

        return <AsyncSelect id={this.props.id} className={'styled-async-select' + (this.props.isError ? ' is-danger' : '')}
            placeholder={ this.usePlaceHolder() ? 'Start typing to search for a school' : this.schoolOptionLabel(this.props.defaultValue)}
            isClearable={true}
            isOptionSelected={() => false}
            cacheOptions
            defaultOptions={true}
            loadOptions={this.schoolOptions}
            getOptionLabel={this.schoolOptionLabel}
            onChange={this.handleChange}
            styles={ this.usePlaceHolder() ? defaultPlaceholderStyle : customPlaceholderStyle}
        />
    }
}

SchoolSelector.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.object,
    isError: PropTypes.bool
}

export default SchoolSelector
