import React from 'react'
import PropTypes from 'prop-types'
import './ProgressSection.scss'
import StyledProgressBar from '../components/StyledProgressBar'

const ProgressSection = ({ id, children, className, style, steps }) => {
    const classNames = `section ${className || ''}`
    return <section id={id} className={classNames} style={style}>
        <div className="container">
            <StyledProgressBar steps={steps}/>
            {children}
        </div>
    </section>
}

ProgressSection.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    steps: PropTypes.objectOf({
        current: PropTypes.number,
        total: PropTypes.number
    }),
    id: PropTypes.string
}

export default ProgressSection
