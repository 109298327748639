import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../components/Modal'

function OrganisedSportSessionsInfoModal (props) {
    const { closeModal } = props
    return <Modal title="Organised sports or activities"
        closeModal={closeModal}
        body={<div>
            <p>
              Organised sports or activities are structured sessions arranged by a club, association, school, or other
              type of organisation.
            </p>
        </div>
        }
    />
}

OrganisedSportSessionsInfoModal.propTypes = {
    closeModal: PropTypes.func.isRequired
}

export default OrganisedSportSessionsInfoModal
