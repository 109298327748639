import React from 'react'
import PropTypes from 'prop-types'
import { ProgressBar } from '@snsw/react-component-library'
import './StyledProgressBar.scss'

const StyledProgressBar = (props) => {
    if (props.steps) {
        return <div className="StyledProgressBar">
            <div className="step-title">Step {props.steps.current} of {props.steps.total}</div>

            <ProgressBar total={props.steps.total} current={props.steps.current} maxWidth={true}/>
        </div>
    } else {
        return null
    }
}

StyledProgressBar.propTypes = {
    steps: PropTypes.shape({
        current: PropTypes.number,
        total: PropTypes.number
    })
}

export default StyledProgressBar
