import React from 'react'
import PropTypes from 'prop-types'
import './Notification.scss'
import IconError from '../icons/icon-error.svg'
import IconWarning from '../icons/icon-warning.svg'
import IconSuccess from '../icons/icon-success.svg'
import IconInfo from '../icons/icon-info.svg'

const Notification = (props) => <div className={`notification is-${props.type} columns is-mobile`}>
    { props.type === 'danger' && <img className="error-icon" alt="Error Icon" src={IconError}/>}
    { props.type === 'info' && <img className="info-icon" alt="Info Icon" src={IconInfo}/>}
    { props.type === 'success' && <img className="success-icon" alt="Success Icon" src={IconSuccess}/>}
    { props.type === 'warning' && <img className="warning-icon" alt="Warning Icon" src={IconWarning}/>}
    <p className="column is-11-widescreen is-10-tablet is-9-mobile">
        {props.message}
    </p>
</div>

Notification.propTypes = {
    type: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
}

export default Notification
