import React from 'react'
import PropTypes from 'prop-types'
import './OtherProducts.scss'
import CardArrow from '../icons/card-arrow.svg'

const OtherProducts = (props) => {
    return <div className='is-center other-products'>
        <h2 className="is-3 title">Other products you might be eligible for</h2>
        <div className="columns">
            {OtherProductsDetails
                .filter(details => details.voucherType === undefined || details.voucherType === props.voucherType)
                .map(details => <div key={details.title} className="column">
                    <div className="product-card">
                        <img src={details.imageLink} alt={details.title} />
                        <div className="card-details">
                            <h3 className="is-5 title">{details.title}</h3>
                            <p>{details.description}</p>
                            <a className="arrow-link" target="_new" href={details.linkTarget}><img src={CardArrow} alt="arrow"/></a>
                        </div>
                    </div>
                </div>)}
        </div>
    </div>
}

const OtherProductsDetails = [
    {
        title: 'Creative Kids',
        voucherType: 'AK',
        imageLink: 'https://www.service.nsw.gov.au/sites/default/files/styles/card_phone_m_2x/public/2019-06/GuitarBoy_1540x866.jpg?itok=Xh-PbjPb',
        description: "$50 voucher for children's creative and cultural activities.",
        linkTarget: 'https://www.service.nsw.gov.au/transaction/apply-creative-kids-voucher'
    },
    {
        title: 'Active Kids',
        voucherType: 'CK',
        imageLink: 'https://www.service.nsw.gov.au/sites/default/files/styles/card_phone_m_1x/public/2019-05/Hindi%20boy%20cricket.jpg?itok=6FQR0RsB',
        description: "$50 voucher for children's sport and physical activities.",
        linkTarget: 'https://www.service.nsw.gov.au/transaction/apply-active-kids-voucher'
    },
    {
        title: 'Energy Switch',
        imageLink: 'https://www.service.nsw.gov.au/sites/default/files/styles/card_phone_m_2x/public/2018-11/C15485_Key_Visual_2_F_300dpi.jpg?itok=7oWIyKES',
        description: 'Compare electricity plans to help you save.',
        linkTarget: 'https://www.service.nsw.gov.au/transaction/use-energy-switch-comparison-tool'
    },
    {
        title: 'Toll Relief',
        imageLink: 'https://www.service.nsw.gov.au/sites/default/files/styles/card_phone_m_2x/public/2018-10/col-toll--2x.jpg?itok=M2283-j4',
        description: 'Free vehicle registration for frequent toll users. ',
        linkTarget: 'https://www.service.nsw.gov.au/transaction/claim-your-toll-relief'
    }
]

OtherProducts.propTypes = {
    voucherType: PropTypes.string.isRequired
}

export default OtherProducts
