import React from 'react'
import PropTypes from 'prop-types'
import './Modal.scss'

const Modal = ({ title, closeModal, body }) => {
    return <div className="modal is-active">
        <div className="modal-background" onClick={closeModal}/>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title" style={{ flexShrink: 1, lineHeight: 1.5 }}>
                    {title}
                </p>
                <button className="delete-simple" aria-label="close" onClick={closeModal}>
                    X
                </button>
            </header>
            <section className="modal-card-body">
                {body}
            </section>
        </div>
    </div>
}

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.node.isRequired,
    closeModal: PropTypes.func.isRequired
}

export default Modal
