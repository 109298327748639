import React from 'react'
import AuthClient from '../components/AuthClient'
import PageComponent from '../components/PageComponent'
import Spinner from '../components/Spinner'
import { getVoucherTypeFromPath } from '../VoucherTypeDetails'
import PropTypes from 'prop-types'

export class LoginPage extends PageComponent {
    async componentDidMount () {
        AuthClient.initiateAuthFlow(getVoucherTypeFromPath(this.props.location.pathname))
    }

    render = () => <Spinner />
}

LoginPage.propTypes = {
    location: PropTypes.object.isRequired
}

export default LoginPage
