import React from 'react'
import PropTypes from 'prop-types'

const ActionButton = (props) =>
    <button className={`button is-flex-mobile ${props.processing ? 'is-loading' : ''} ${props.className || ''}`} onClick={props.onClick}
        disabled={props.disabled || props.processing}>
        {props.text}
    </button>

ActionButton.propTypes = {
    className: PropTypes.string,
    processing: PropTypes.bool,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default ActionButton
