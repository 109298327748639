import React from 'react'
import Loader from './loader.gif'
import Section from '../layout/ProgressSection'

const Spinner = () => {
    return <Section className='is-centered' style={{ width: '100%' }}>
        <div style={{ textAlign: 'center' }}>
            <img src={Loader} alt='loading...'/>
            <p className="loader__message">One moment, please...<br/></p>
        </div>
    </Section>
}

export default Spinner
