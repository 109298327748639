import AuthClient from '../components/AuthClient'

const VouchersApi = {
    allocate: async (data) => {
        const response = await fetch(process.env.REACT_APP_BACKEND_API + '/vouchers', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: AuthClient.appendAuthHeaders({
                'Content-Type': 'application/json'
            })
        })

        if (response.ok) { return response.json() }
        return { error: true }
    },
    send: async (voucherCode, email) => {
        await fetch(process.env.REACT_APP_BACKEND_API + '/vouchers/send', {
            method: 'POST',
            body: JSON.stringify({ voucherCode, email }),
            headers: AuthClient.appendAuthHeaders({
                'Content-Type': 'application/json'
            })
        })
    }
}

export default VouchersApi
