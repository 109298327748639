import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ThumbsUpThumbsDown extends Component {
    componentDidMount () {
        const body = document.body
        const script = document.createElement('script')
        script.async = true
        script.defer = true
        script.type = 'text/javascript'
        script.id = 'quickfeed-script-loader'
        script.src = process.env.REACT_APP_TUTD_SCRIPT_URL
        script.onload = this.initQuickfeed

        const existingScriptLoader = document.getElementById(script.id)
        if (!existingScriptLoader) {
            body.appendChild(script)
        } else {
            this.initQuickfeed()
        }
    }

    initQuickfeed = () => {
        window.quickFeed.init({
            placeholder: 'quickFeedHolder',
            label: this.props.label,
            environment: process.env.REACT_APP_TUTD_ENV,
            sourceApplication: this.props.sourceApplication
        })
    }

    render () {
        return <div id="quickFeedHolder"></div>
    }
}

ThumbsUpThumbsDown.propTypes = {
    label: PropTypes.string.isRequired,
    sourceApplication: PropTypes.string.isRequired
}

export default ThumbsUpThumbsDown
