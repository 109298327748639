import React from 'react'
import PropTypes from 'prop-types'
import './ChildIdentityCheck.scss'
import ChildIdentityApi from './ChildIdentityApi'
import moment from 'moment'
import { connect } from 'unistore/react'
import actions from '../actions'
import ChildEligibilityError from './ChildEligibilityError'
import IrnInfoModal from './IrnInfoModal'
import { dvsDocType, dvsField, validateDvsField } from 'dvs-validation'
import ActionButton from '../components/ActionButton'
import InputWithLabelAndErrorMessage from '../components/InputWithLabelAndErrorMessage'
import StyledCheckbox from '../components/StyledCheckbox'
import { getVoucherTypeDetails } from '../VoucherTypeDetails'
import ReactGA from 'react-ga'
import { Notice } from '@snsw/react-component-library'

const longNameLabels = [
    { index: 1, label: 'First' },
    { index: 2, label: 'Second' },
    { index: 3, label: 'Third' },
    { index: 4, label: 'Fourth' }
]
export class ChildIdentityCheck extends React.Component {
    static propTypes = {
        transactionContext: PropTypes.object.isRequired,
        setChildren: PropTypes.func.isRequired,
        onChange: PropTypes.func,
        voucherType: PropTypes.string.isRequired
    }

    constructor (props) {
        super(props)
        const children = props.transactionContext.children
        let expiryDate = children.length > 0 ? children[0].expiryDate : ''
        if (expiryDate) {
            expiryDate = this.convertIsoToAuDate(expiryDate)
        }
        this.state = {
            lockCardDetails: children.length > 0,
            firstName: '',
            middleName: '',
            lastName: '',
            cardNumber: children.length > 0 ? children[0].cardNumber : '',
            individualReferenceNumber: null,
            cardType: children.length > 0 ? children[0].cardType : 'G',
            expiryDate,
            birthDate: '',
            infoModal: null,
            eligibilityCheckResult: null,
            cardInvalid: false,
            cardInvalidReason: '',
            individualReferenceNumberInvalid: false,
            individualReferenceNumberInvalidReason: '',
            expiryDateInvalid: false,
            expiryDateInvalidReason: '',
            birthDateInvalid: false,
            birthDateInvalidReason: '',
            isSubmitting: false,
            isLongName: true,
            fullNameLine1: '',
            fullNameLine2: '',
            fullNameLine3: '',
            fullNameLine4: '',
            nameLines: 1,
            longNameInvalid: false,
            longNameInvalidReason: '',
            additionalNameInvalidReason: {},
            triedSubmitting: false
        }

        this.references = {
            cardNumber: React.createRef(),
            expiryDate: React.createRef(),
            birthDate: React.createRef(),
            fullNameLine1: React.createRef(),
            individualReferenceNumber: React.createRef()
        }
    }

    handleCardTypeChange = async (event) => {
        await this.setState({ cardType: event.target.value })
        this.updateExpiryDateValiditySkipBlankCheck()
    }

    dateWithDashes = (date) => {
        return date.split('/').reverse().join('-')
    }

    convertIsoToAuDate = (date) => {
        return date.split('-').reverse().join('/')
    }

    buildLongNames = () => {
        return this.state.isLongName ? [this.state.fullNameLine1, this.state.fullNameLine2, this.state.fullNameLine3, this.state.fullNameLine4]
            .filter((item, index) => item.length > 0 && index < this.state.nameLines)
            .map(item => item.trim())
            : []
    }

    recordPageView = (name) => {
        try {
            ReactGA.set({ page: `${window.location.pathname}/${name}` })
            ReactGA.pageview(`${window.location.pathname}/${name}`)
        } catch (err) {
        }
    }

    handleSubmit = () => {
        const notValid = this.isFormFilled()
        if (notValid) {
            this.updateCardNumberValidity()
            this.updateExpiryDateValidity()
            this.updateAgeEligibility()
            this.updateIndividualReferenceNumberValidity()
            this.updateFullNameValidity()

            longNameLabels.forEach(n => {
                if (n.index > 1 && n.index <= this.state.nameLines) {
                    this.updateAdditionalNameValidity('fullNameLine' + n.index)
                }
            })

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
            this.setState({
                triedSubmitting: true
            })
            return
        }
        this.setState({ isSubmitting: true })
        const { firstName, middleName, lastName, cardNumber, individualReferenceNumber, cardType, expiryDate, birthDate } = this.state

        const alreadySelected = this.props.transactionContext.children.reduce(
            (acc, current) => acc || current.individualReferenceNumber === individualReferenceNumber, false
        )
        if (alreadySelected) {
            this.setState({ eligibilityCheckResult: { status: 'ERROR_ALREADY_ADDED' }, isSubmitting: false })
        } else {
            const childRequest = {
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                cardNumber: cardNumber,
                longNames: this.buildLongNames(),
                individualReferenceNumber: individualReferenceNumber,
                cardType: cardType,
                expiryDate: this.dateWithDashes(expiryDate),
                birthDate: this.dateWithDashes(birthDate),
                voucherType: this.props.voucherType
            }
            ChildIdentityApi.check(childRequest).then((response) => {
                this.setState({ eligibilityCheckResult: response, isSubmitting: false })
                if (response.status === 'ELIGIBLE') {
                    this.props.setChildren(this.props.transactionContext.children.concat([{
                        ...childRequest,
                        eligibilityCheckResult: response
                    }]))
                    if (this.props.onChange) {
                        this.props.onChange()
                    }
                } else {
                    this.setState({ eligibilityCheckResult: { ...response, voucherType: this.props.voucherType }, isSubmitting: false })
                    this.recordPageView(response.status)
                }
            }).catch(reason => {
                this.setState({ eligibilityCheckResult: { status: 'ERROR' }, isSubmitting: false })
                this.recordPageView('system-error')
            })
        }
    }

    updateAgeEligibility = () => {
        const birthDate = moment(this.state.birthDate, 'DD/MM/YYYY', true)
        const ageInMonths = moment().diff(birthDate, 'months')
        if (!birthDate.isValid()) {
            this.setState({ birthDateInvalidReason: 'Please enter the DOB' })
        } else if (ageInMonths < (12 * 4.5) || ageInMonths >= 19 * 12) {
            this.setState({
                birthDateInvalidReason: 'Unfortunately, this child isn’t eligible for ' +
                    (this.props.voucherType === 'AK' ? 'an ' : 'a ') +
                    getVoucherTypeDetails((this.props.voucherType)).name +
                    ' voucher because they do not meet the eligible age requirement (between 4.5 to 18 years of age)'
            })
        }
        const birthDateInvalid = !birthDate.isValid() || ageInMonths < (12 * 4.5) || ageInMonths >= 19 * 12
        this.setState({ birthDateInvalid })
    }

    updateCardNumberValidity = () => {
        const result = validateDvsField(dvsDocType.MEDICARE, dvsField.MEDICARE.CARD_NUMBER, this.state.cardNumber)
        this.setState({ cardInvalid: !result.success })
        if (!result.success) this.setState({ cardInvalidReason: result.error.text })
    }

    updateIndividualReferenceNumberValidity = () => {
        let result = validateDvsField(dvsDocType.MEDICARE, dvsField.MEDICARE.INDIVIDUAL_REF_NUMBER, this.state.individualReferenceNumber)
        // NOTE: at this stage the library doesn't seem to provide any validation except required
        //       and then it doesnt give a message... so we will do it here
        if (result.success && (!Number.isInteger(Number(this.state.individualReferenceNumber)) ||
            Number(this.state.individualReferenceNumber) < 1 ||
            Number(this.state.individualReferenceNumber) > 9
        )) {
            result = { success: false, error: { text: 'The IRN is a number from 1 to 9' } }
        }
        this.setState({ individualReferenceNumberInvalid: !result.success })
        if (!result.success) this.setState({ individualReferenceNumberInvalidReason: result.error.text })
    }

    updateExpiryDateValidity = () => {
        let result
        if (this.state.cardType === 'G') {
            result = validateDvsField(dvsDocType.MEDICARE, dvsField.MEDICARE.GREEN_EXPIRY_DATE, this.state.expiryDate)
        } else {
            result = validateDvsField(dvsDocType.MEDICARE, dvsField.MEDICARE.OTHER_EXPIRY_DATE, this.state.expiryDate)
        }
        this.setState({ expiryDateInvalid: !result.success })
        if (!result.success) this.setState({ expiryDateInvalidReason: result.error.text })
    }

    updateExpiryDateValiditySkipBlankCheck = () => {
        if (this.state.expiryDate !== '') {
            this.updateExpiryDateValidity()
        }
    }

    updateFullNameValidity = () => {
        let error = ''
        if (this.state.fullNameLine1.length > 27) {
            error = 'Line 1 must be no more than 27 characters'
        }

        if (this.state.fullNameLine1.indexOf('  ') !== -1) {
            error = 'The name must not contain two spaces together'
        }

        if (!this.state.fullNameLine1) {
            error = 'Child\'s name (exactly as shown on your Medicare card) is required'
        }
        this.setState({
            longNameInvalid: !!error,
            longNameInvalidReason: error ?? this.state.longNameInvalidReason
        })
    }

    updateAdditionalNameValidity = (name) => {
        const errors = []
        if (this.state[name].indexOf('  ') !== -1) {
            errors.push(<div className="line-double-space-error">The name must not contain two spaces together</div>)
        }
        const additionalNameInvalidReason = this.state.additionalNameInvalidReason
        additionalNameInvalidReason[name] = errors
        this.setState({
            additionalNameInvalidReason
        })
    }

    isChildNameValid () {
        const longNames = this.buildLongNames()
        const longNameErrors = longNameLabels.map(n => {
            if (n.index > 1 && n.index <= this.state.nameLines) {
                return this.state.additionalNameInvalidReason[`fullNameLine${n.index}`] &&
                this.state.additionalNameInvalidReason[`fullNameLine${n.index}`].length > 0
            } else {
                return false
            }
        }).some(x => x)
        if (longNameErrors) return false
        return (
            (this.state.fullNameLine1 &&
                longNames.length > 0 &&
                // no double spaces
                longNames.every(segment => segment.indexOf('  ') === -1) &&
                longNames[0].length <= 27
            )
        )
    }

    shortNameCombined = () => `${this.state.firstName} ${this.state.middleName} ${this.state.lastName}`.replace('  ', ' ').trim()

    isFormFilled = () => this.state.birthDateInvalid ||
        this.state.cardInvalid ||
        this.state.individualReferenceNumberInvalid ||
        this.state.expiryDateInvalid || !(
        this.state.birthDate &&
            this.state.cardNumber &&
            this.state.expiryDate &&
            this.state.individualReferenceNumber &&
            this.isChildNameValid()
    )

    getAnchorItem = ({ name, reason, refName }) => {
        if (this.state[name]) {
            return <li key={`anchor-item-${name}`} className="anchor-item" onClick={() => {
                this.references[refName].current.scrollIntoView({ behavior: 'smooth' })
            }}>{this.state[reason] ?? ''}</li>
        }
        return null
    }

    render () {
        const formHasErrors = this.state.triedSubmitting && this.isFormFilled()
        let formErrors = null
        if (formHasErrors) {
            formErrors = [
                { name: 'cardInvalid', reason: 'cardInvalidReason', refName: 'cardNumber' },
                { name: 'expiryDateInvalid', reason: 'expiryDateInvalidReason', refName: 'expiryDate' },
                { name: 'birthDateInvalid', reason: 'birthDateInvalidReason', refName: 'birthDate' },
                { name: 'longNameInvalid', reason: 'longNameInvalidReason', refName: 'fullNameLine1' },
                { name: 'individualReferenceNumberInvalid', reason: 'individualReferenceNumberInvalidReason', refName: 'individualReferenceNumber' }
            ].map((item) => {
                return this.getAnchorItem(item)
            }).filter(x => x)
        }

        return (
            <div className="child-identity-check">
                { formHasErrors && formErrors.length > 0 && <Notice error>
                    <Notice.Title>Error</Notice.Title>
                    <Notice.Copy>
                        <span>
                            <span>{`Your form has the following ${formErrors.length} error${formErrors.length > 1 ? 's' : ''}`}</span>
                            <br/><br/>
                            <ul>
                                {formErrors}
                            </ul>
                        </span>
                    </Notice.Copy>
                </Notice>}
                <h2 className="title is-5">Enter the following Medicare card details exactly as shown on the child's Medicare card</h2>
                <div ref={this.references.cardNumber}>
                    <InputWithLabelAndErrorMessage
                        name="cardNumber"
                        label={<div>Medicare card number <span key="name" className="required_color">*</span></div>}
                        type="number"
                        value={this.state.cardNumber}
                        disabled={!!this.state.lockCardDetails}
                        errorMessage={this.state.cardInvalidReason}
                        showErrorMessage={this.state.cardInvalid}
                        onChange={(e) => this.setState({ cardNumber: e.target.value })}
                        onBlur={this.updateCardNumberValidity}/>
                </div>

                <label className="label" htmlFor="cardType">Card type <span key="name" className="required_color">*</span></label>
                <select className="input" name="cardType" defaultValue={this.state.cardType}
                    disabled={!!this.state.lockCardDetails}
                    onChange={this.handleCardTypeChange.bind(this)}>
                    <option value="G">Green (Australian Resident)</option>
                    <option value="Y">Yellow (Reciprocal health care agreement)</option>
                    <option value="B">Blue (Interim card)</option>
                </select>

                <div ref={this.references.expiryDate}>
                    <InputWithLabelAndErrorMessage
                        name="expiryDate"
                        label={<div>Expiry Date ({this.state.cardType === 'G' ? 'MM/YYYY' : 'DD/MM/YYYY'}) <span key="name" className="required_color">*</span></div> }
                        value={this.state.expiryDate ? this.convertIsoToAuDate(this.state.expiryDate) : ''}
                        disabled={!!this.state.lockCardDetails}
                        errorMessage={this.state.expiryDateInvalidReason}
                        showErrorMessage={this.state.expiryDateInvalid}
                        onChange={(e) => this.setState({ expiryDate: e.target.value })}
                        onBlur={this.updateExpiryDateValidity}/>
                </div>

                <div className="child-information-form">
                    <div className="info-box grey-box is-size-5">
                        Check eligibility by entering specific details of a child
                    </div>

                    <div ref={this.references.birthDate}>
                        <InputWithLabelAndErrorMessage
                            name="birthDate"
                            label={<span>Child's date of birth (DD/MM/YYYY) <span key="name" className="required_color">*</span></span>}
                            errorMessage={this.state.birthDateInvalidReason}
                            showErrorMessage={this.state.birthDateInvalid}
                            onChange={(e) => this.setState({ birthDate: e.target.value })}
                            onBlur={this.updateAgeEligibility}/>
                    </div>

                    { longNameLabels.map(n => {
                        if (n.index === 1 || this.state.nameLines > (n.index - 1)) {
                            const errors = this.state.additionalNameInvalidReason[`fullNameLine${n.index}`] ?? []
                            return <div key={n.index} ref={n.index === 1 ? this.references.fullNameLine1 : undefined}>
                                <InputWithLabelAndErrorMessage
                                    name={'fullNameLine' + n.index}
                                    label={<span>{
                                        (n.index === 1 ? <span>Child's full name (as shown on your card) <span key="name" className="required_color">*</span></span>
                                            : `${n.label} line of child's name (exactly as shown on your Medicare card)`)}</span>}
                                    sublabel={n.index === 1 ? "If your child's name appears on more than one line, select 'Add another line'" : ''}
                                    errorMessage={n.index === 1 ? this.state.longNameInvalidReason : errors}
                                    showErrorMessage={n.index === 1 ? this.state.longNameInvalid : errors.length > 0}
                                    onChange={(e) => this.setState({ ['fullNameLine' + n.index]: e.target.value },
                                        () => {
                                            n.index === 1 ? this.updateFullNameValidity()
                                                : this.updateAdditionalNameValidity(`fullNameLine${n.index}`)
                                        })}
                                    onBlur={() => {
                                        n.index === 1 ? this.updateFullNameValidity()
                                            : this.updateAdditionalNameValidity(`fullNameLine${n.index}`)
                                    }}
                                />

                                { n.index < 4 && <div key={n.index} className="add-new-line columns is-mobile">
                                    <div className="column is-narrow">
                                        <StyledCheckbox name={'addAnotherLine' + n.index}
                                            checked={this.state.nameLines > n.index}
                                            onChange={() => this.setState({
                                                nameLines: this.state.nameLines === n.index ? n.index + 1 : n.index
                                            })}/>
                                    </div>
                                    <div className="column">
                                    Add another line
                                    </div>
                                </div>}
                            </div>
                        } else {
                            return <div key={n.index}/>
                        }
                    }
                    )}

                    <div ref={this.references.individualReferenceNumber}>
                        <InputWithLabelAndErrorMessage
                            name="individualReferenceNumber"
                            label={
                                <div className="irn-label">
                                    <div>Child's individual reference number (IRN) <span key="name" className="required_color">*</span></div>
                                    <div className='is-pulled-right has-text-link label-link' onClick={() => {
                                        this.setState({ infoModal: 'irn' })
                                        this.recordPageView('irn-help')
                                    }}>
                                    Where do I find this?
                                    </div>
                                </div>
                            }
                            type="number"
                            errorMessage={this.state.individualReferenceNumberInvalidReason}
                            showErrorMessage={this.state.individualReferenceNumberInvalid}
                            onChange={(e) => this.setState({ individualReferenceNumber: e.target.value })}
                            onBlur={this.updateIndividualReferenceNumberValidity}
                        />
                    </div>

                    {this.showError() && <ChildEligibilityError checkResult={this.state.eligibilityCheckResult}/>}

                    <div>
                        <ActionButton
                            className="is-primary"
                            disabled={false}
                            text="Check eligibility"
                            processing={this.state.isSubmitting}
                            onClick={this.handleSubmit}
                        />
                    </div>
                </div>
                {
                    this.state.infoModal && <IrnInfoModal closeModal={() => this.setState({ infoModal: null })}/>
                }
            </div>
        )
    }

    escFunction = () => {
        if (this.state.infoModal) {
            this.setState({ infoModal: null })
        }
    }

    componentDidMount () {
        document.addEventListener('keydown', this.escFunction, false)
    }

    componentWillUnmount () {
        document.removeEventListener('keydown', this.escFunction, false)
    }

    showError = () => {
        return this.state.eligibilityCheckResult !== null && this.state.eligibilityCheckResult.status !== 'ELIGIBLE' && this.state.eligibilityCheckResult.status !== ''
    }
}

export default connect('transactionContext', actions)(ChildIdentityCheck)
