import React from 'react'
import PropTypes from 'prop-types'
import ResearchDataClient from './ResearchDataClient'
import ActionButton from '../components/ActionButton'
import VouchersApi from './VouchersApi'
import { connect } from 'unistore/react'
import actions from '../actions'
import PageComponent from '../components/PageComponent'
import './ResearchDataPage.scss'
import ResearchDataInfoBox from './ResearchDataInfoBox'
import ResearchQuestionCorrectionForm from './active-kids/ResearchQuestionCorrectionForm'
import ActiveKidsResearchQuestions from './active-kids/ActiveKidsResearchQuestions'
import CreativeKidsResearchQuestions from './creative-kids/CreativeKidsResearchQuestions'
import Notification from '../components/Notification'
import ReactGA from 'react-ga'
import {
    getPathBase,
    getPathBaseFromVoucherType,
    getVoucherTypeDetails,
    getVoucherTypeFromPath,
    VoucherTypeAK,
    VoucherTypeCK
} from '../VoucherTypeDetails'
import ActiveKidsResearchDataInfoPanel from './active-kids/ActiveKidsResearchDataInfoPanel'
import CreativeKidsResearchDataInfoPanel from './creative-kids/CreativeKidsResearchDataInfoPanel'
import { StyledCheckboxRow } from '../components/StyledCheckboxRow'
import Section from '../layout/ProgressSection'
import { Notice } from '@snsw/react-component-library'

const RD_TYPE_CORRECTIONS = 'corrections'
const RD_TYPE_SECOND = 'second'
const RD_TYPE_FIRST = 'first'

const validFormValues = (formValues, voucherType, needsUpdate, lastChild, acceptTerms, isSecondVoucher, parent) => {
    const validationErrors = {}

    if (!formValues) {
        return validationErrors
    }

    if (isSecondVoucher && voucherType === VoucherTypeAK && !needsUpdate) {
        return validationErrors
    }

    if (!needsUpdate) {
        if (!formValues.address || Object.keys(formValues.address).length === 0) {
            validationErrors.address = 'Address is required.'
        } else if (!formValues?.address?.formattedAddress) {
            validationErrors.address = 'Incomplete or invalid address entered'
        } else if (formValues.address.state && formValues.address.state !== 'NSW') {
            validationErrors.address = `The ${getVoucherTypeDetails(voucherType).name} vouchers are only available to parents, guardians and carers living in NSW.`
        }
    }
    if (!formValues.school) {
        validationErrors.school = 'School is required.'
    }

    if (needsUpdate) {
        if (!formValues.residentialPostCode) {
            validationErrors.residentialPostCode = 'Postcode is required.'
        }
    } else {
        if (!formValues.gender) {
            validationErrors.gender = 'Gender is required.'
        }
        if (!formValues.mainLanguageAtHome) {
            validationErrors.mainLanguageAtHome = 'Main language spoken at home is required.'
        }
        if (!formValues.atsiStatus) {
            validationErrors.atsiStatus = 'Is this child of Aboriginal or Torres Strait Islander heritage? is required.'
        }
        if (!formValues.disability) {
            validationErrors.disability = 'Does this child have a disability? is required.'
        }

        if (voucherType === VoucherTypeAK) {
            if (!formValues.height) {
                validationErrors.height = 'Height (in cm) is required.'
            }
            if (!formValues.weight) {
                validationErrors.weight = 'Weight (in kg) is required.'
            }
            if (!formValues.activityDays) {
                validationErrors.activityDays = 'How many days is you child active? is required.'
            }
            if (!formValues.activitySessionsPerYear) {
                validationErrors.activitySessionsPerYear = 'How many sessions of organised sport did your child participate in? is required.'
            }
            if (!formValues.expectedActivity) {
                validationErrors.expectedActivity = 'Which activity do you plan on using this voucher? is required.'
            }
        } else if (voucherType === VoucherTypeCK) {
            if (!formValues.intendedUseActivity) {
                validationErrors.intendedUseActivity = 'What creative or cultural activity will this voucher be used for? is required.'
            } else if (formValues.intendedUseActivity !== 'Don’t know yet') {
                if (!formValues.intendedUseActivityPreviousParticipation) {
                    validationErrors.intendedUseActivityPreviousParticipation = 'Has this child participated in this activity before? is required.'
                }
                if (!formValues.intendedUseActivityWouldParticipateWithoutVoucher) {
                    validationErrors.intendedUseActivityWouldParticipateWithoutVoucher = 'Would this child have participated in this activity without the voucher? is required.'
                }
            }
            if (!formValues.participationInLast12Months) {
                validationErrors.participationInLast12Months = 'In the last 12 months, has this child participated in a creative or cultural activity? is required.'
            } else if (formValues.participationInLast12Months === 'Yes') {
                if (!formValues.previousActivities) {
                    validationErrors.previousActivities = 'In the last 12 months, what creative or cultural activities has this child participated in? is required.'
                }
                if (!formValues.hoursOfParticipation) {
                    validationErrors.hoursOfParticipation = 'On average, how many hours a week does this child participate in a creative or cultural activity? is required.'
                }
            }
        }
    }

    if (lastChild) {
        if (!acceptTerms) {
            validationErrors.acceptTerms = 'You must accept the Terms and Conditions.'
        }
    }

    return validationErrors
}

export class ResearchDataPage extends PageComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        transactionContext: PropTypes.object.isRequired,
        parent: PropTypes.object.isRequired,
        addVoucher: PropTypes.func.isRequired,
        clearChildren: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        if (props.transactionContext.children && props.transactionContext.children.length === 0) {
            this.props.history.push({ pathname: `/${getPathBase(props.location.pathname)}/error/rd-no-transaction` })
        }

        this.state = {
            children: this.props.transactionContext.children.map((eligibleChild, index) => {
                const rdType = this.isSecondVoucher(eligibleChild) ? (this.needsResearchDataUpdates(eligibleChild) ? RD_TYPE_CORRECTIONS : RD_TYPE_SECOND) : RD_TYPE_FIRST
                return {
                    childSequence: index,
                    type: rdType,
                    label: ((eligibleChild.longNames && eligibleChild.longNames.length > 0) ? eligibleChild.longNames[0] : eligibleChild.firstName) || 'your child',
                    details: eligibleChild,
                    isValid: (rdType === RD_TYPE_SECOND),
                    formValues: {
                        address: {
                            formattedAddress: props?.parent?.address?.formattedAddress ?? ''
                        }
                    },
                    savedResearchData: false,
                    voucher: null
                }
            }),
            selectedChildIndex: 0,
            spinner: false,
            infoModal: null,
            contactConsent: false,
            acceptTerms: false,
            voucherType: getVoucherTypeFromPath(this.props.location.pathname),
            didSubmit: false,
            errors: {}
        }

        this.references = []
        for (let i = 0; i < this.state.children.length; i++) {
            this.references.push({
                address: React.createRef(),
                school: React.createRef(),
                gender: React.createRef(),
                mainLanguageAtHome: React.createRef(),
                atsiStatus: React.createRef(),
                disability: React.createRef(),
                // ak fields
                height: React.createRef(),
                weight: React.createRef(),
                activityDays: React.createRef(),
                activitySessionsPerYear: React.createRef(),
                expectedActivity: React.createRef(),
                // ck fields
                intendedUseActivity: React.createRef(),
                intendedUseActivityPreviousParticipation: React.createRef(),
                intendedUseActivityWouldParticipateWithoutVoucher: React.createRef(),
                participationInLast12Months: React.createRef(),
                previousActivities: React.createRef(),
                hoursOfParticipation: React.createRef(),
                residentialPostCode: React.createRef(),
                acceptTerms: React.createRef()
            })
        }
    }

    updateConsent = () => {
        this.setState({ contactConsent: !this.state.contactConsent })
    }

    updateAcceptTerms = () => {
        this.setState(state => ({ acceptTerms: !state.acceptTerms }))
    }

    handleCorrectionInput = (child) => (data) => {
        this.state.children[child.childSequence].isValid = data.isValid
        this.state.children[child.childSequence].formValues.school = data.school
        this.state.children[child.childSequence].formValues.schoolSuburb = data.schoolSuburb
        this.state.children[child.childSequence].formValues.residentialPostCode = data.residentialPostCode

        this.setState({ children: this.state.children })
    }

    handleResearchAnswersChange = (child) => (data) => {
        this.state.children[child.childSequence].isValid = data.isValid
        this.state.children[child.childSequence].formValues = data.formValues

        this.setState({ children: this.state.children })
    }

    getChildDataForCorrectionsApi = child => {
        return {
            longNames: child.longNames,
            firstName: child.firstName,
            lastName: child.lastName,
            cardNumber: child.cardNumber,
            individualReferenceNumber: child.individualReferenceNumber,
            birthDate: child.birthDate,
            middleName: child.middleName
        }
    }

    isAllSubmitsFailed = () => this.state.children.reduce((sum, next) => sum && !!next.error, true)

    checkValidationErrors = () => {
        const errors = validFormValues(this.state.children[this.state.selectedChildIndex]?.formValues,
            this.state.voucherType,
            this.needsResearchDataUpdates(this.state.children[this.state.selectedChildIndex].details),
            this.state.children.length === 1 || (this.state.selectedChildIndex >= this.state.children.length - 1),
            this.state.acceptTerms,
            this.isSecondVoucher(this.state.children[this.state.selectedChildIndex].details),
            this.props.parent)

        if (Object.keys(errors).length > 0) {
            this.setState({
                didSubmit: true,
                errors
            })
            window.scrollTo(0, 0)
            return true
        }

        return false
    }

    handleContinue = () => {
        if (!this.checkValidationErrors()) {
            this.setState({
                didSubmit: false,
                selectedChildIndex: this.state.selectedChildIndex + 1,
                errors: {}
            })
            window.scrollTo(0, 0)
        }
    }

    handleSubmit = async () => {
        this.setState({ error: false, spinner: true })
        if (this.checkValidationErrors()) {
            this.setState({ spinner: false })
            return
        }
        this.setState({ didSubmit: false })
        for (let index = 0; index < this.state.children.length; index++) {
            await this.handleSubmitForChild(this.state.children[Number(index)])
        }
        this.setState({ spinner: false })
        if (!this.isAllSubmitsFailed()) {
            this.state.children
                .filter(child => !!child.error)
                .forEach(child => this.props.addVoucher({
                    error: true,
                    nameOnVoucher: this.createFullName(child.details)
                }))
            this.props.clearChildren()
            this.props.history.push({ pathname: `/${getPathBaseFromVoucherType(this.state.voucherType)}/confirmation` })
        } else {
            window.scrollTo(0, 0)
        }
    }

    handleSubmitForChild = async (child) => {
        let researchDataResponse = { ok: false }
        child.error = false
        try {
            switch (child.type) {
            case RD_TYPE_CORRECTIONS:
                researchDataResponse = await ResearchDataClient.submitCorrections({
                    school: child.formValues.school,
                    schoolSuburb: child.formValues.schoolSuburb,
                    residentialPostCode: child.formValues.residentialPostCode,
                    childData: this.getChildDataForCorrectionsApi(child.details)
                })
                break
            case RD_TYPE_FIRST:
                if (!child.researchDataResponse?.ok) {
                    researchDataResponse = await ResearchDataClient.submit(this.prepareResearchData(child), this.state.voucherType)
                    // store the research data response in case the voucher creation fails
                    // so we don't try to create it twice when the customer retries
                    child.researchDataResponse = researchDataResponse
                } else {
                    researchDataResponse = child.researchDataResponse
                }
                break
            case RD_TYPE_SECOND:
                researchDataResponse = { ok: true }
                break
            default:
                researchDataResponse = { ok: false }
                break
            }
        } catch (err) {
            this.recordPageView(`research-save-error/${child.type}`, err)
            researchDataResponse = { ok: false }
        }
        child.savedResearchData = researchDataResponse.ok
        if (researchDataResponse.ok) {
            await this.allocateVoucherAndRedirect(child)
        } else {
            child.error = true
        }
    }

    recordPageView = (name, title) => {
        try {
            ReactGA.set({ page: `${this.props.location.pathname}/${name}` })
            if (title) {
                ReactGA.set({ title: title })
            }
            ReactGA.pageview(`${this.props.location.pathname}/${name}`)
        } catch (err) {
        }
    }

    prepareResearchData (child) {
        const researchDataBody = {
            researchData: {
                ...child.formValues,
                contactConsent: this.state.contactConsent
            },
            childData: child.details
        }
        return researchDataBody
    }

    createFullName = (childDetails) => (childDetails.longNames && childDetails.longNames.length > 0)
        ? childDetails.longNames.join(' ')
        : childDetails.firstName + ' ' + childDetails.lastName

    getTermsAndConditionsUri = () => getVoucherTypeDetails(this.state.voucherType).termsAndConditionsUri

    getPrivacyUrl = () => getVoucherTypeDetails(this.state.voucherType).privacyUrl

    async allocateVoucherAndRedirect (child) {
        const childDetails = child.details
        const allocateData = {
            voucherType: this.state.voucherType,
            birthDate: childDetails.birthDate,
            firstName: childDetails.firstName,
            middleName: childDetails.middleName,
            lastName: childDetails.lastName,
            cardNumber: childDetails.cardNumber,
            individualReferenceNumber: childDetails.individualReferenceNumber,
            longNames: childDetails.longNames,
            parentName: this.props.parent.name,
            contactNumber: this.props.parent.contactNumber,
            address: this.props.parent.address,
            acceptTerms: this.state.acceptTerms
        }
        try {
            const allocateVoucherResponse = await VouchersApi.allocate(allocateData)
            child.allocatedVoucher = !allocateVoucherResponse.error

            if (!allocateVoucherResponse.error) {
                this.props.addVoucher(allocateVoucherResponse)
            } else {
                child.error = true
            }
        } catch (err) {
            this.recordPageView('voucher-allocate-error')
            child.allocatedVoucher = false
            child.error = true
        }
    }

    isSecondVoucher = (child) => child.eligibilityCheckResult.status === 'ELIGIBLE' && child.eligibilityCheckResult.numberOfIssuedVouchers > 0

    needsResearchDataUpdates = (child) => this.isSecondVoucher(child) &&
        !!(child.eligibilityCheckResult.schoolResearchDataRequired ||
            child.eligibilityCheckResult.residentialPostcodeResearchRequired ||
            child.eligibilityCheckResult.remediated)

    isAllResearchDataValid = () => this.state.children.reduce((sum, next) => sum && next.isValid, true)
    needsContactConsent = (child) => !this.isSecondVoucher(child) || this.needsResearchDataUpdates(child)

    isCorrectionValid = () =>
        this.state.correctionValid

    showNextButton = () =>
        this.state.children && this.state.children.length > 1 &&
        (this.state.selectedChildIndex < this.state.children.length - 1)

    showPrevButton = () => this.state.selectedChildIndex > 0

    render () {
        const voucherTypeDetails = getVoucherTypeDetails(this.state.voucherType)
        const consentRequired = this.state.children
            .reduce((acc, child) => acc || this.needsContactConsent(child.details), false)

        return <div>
            <Section className="research-data" style={{ marginBottom: '28rem' }} steps={{ current: 3, total: 3 }}>

                <div className='columns'>
                    <div className='column is-two-thirds-widescreen'>
                        <h1 className='title is-1'>Help {voucherTypeDetails.name} learn skills they can use for
                            life</h1>

                        {this.isAllSubmitsFailed() && <Notification type="danger" message={
                            <div>
                                <b>Unable to process voucher{this.state.children.length > 1 ? 's' : ''}</b><br/>
                                Unfortunately we were unable to process your
                                voucher{this.state.children.length > 1 ? 's' : ''}.
                                We apologise for the inconvenience, please try again.
                            </div>}/>}
                        {this.state.didSubmit && Object.keys(this.state.errors).length > 0 && <Notice error>
                            <Notice.Title>Error</Notice.Title>
                            <Notice.Copy>
                                <span>
                                    <span>Please check the following {Object.keys(this.state.errors).length} error{Object.keys(this.state.errors).length > 1 ? 's' : ''}.</span>
                                    <br /><br />
                                    <ul>
                                        {Object.entries(this.state.errors).map(([field, reason], idx) => {
                                            return <li
                                                key={idx}
                                                className='anchor-item'
                                                onClick={() => this.references[this.state.selectedChildIndex]?.[field]?.current?.scrollIntoView({ behavior: 'smooth' })}>
                                                {reason}
                                            </li>
                                        })}
                                    </ul>
                                </span>
                            </Notice.Copy>
                        </Notice>}

                        {this.state.voucherType === VoucherTypeAK && <ActiveKidsResearchDataInfoPanel/>}
                        {this.state.voucherType === VoucherTypeCK && <CreativeKidsResearchDataInfoPanel/>}

                        <strong>Child's name</strong>
                        {this.state.children.map((child, index) =>
                            <ResearchDataInfoBox
                                isValid={child.isValid} key={child.childSequence}
                                isSecondVoucher={child.type === RD_TYPE_SECOND || child.type === RD_TYPE_CORRECTIONS}
                                needsResearchDataUpdates={child.type === RD_TYPE_CORRECTIONS}
                                childName={child.label}/>)
                        }
                        <br/>

                        {this.state.children.map((child, index) => <div key={child.childSequence}>
                            {this.needsResearchDataUpdates(child.details) &&
                                <ResearchQuestionCorrectionForm
                                    onChange={this.handleCorrectionInput(child)}
                                    hidden={this.state.selectedChildIndex !== index}
                                    didSubmit={this.state.didSubmit}
                                    refs={this.references[index]}
                                    errors={this.state.errors}/>
                            }
                            {!this.isSecondVoucher(child.details) && this.state.voucherType === VoucherTypeAK &&
                                <ActiveKidsResearchQuestions id="research-questions" child={child}
                                    parent={this.props.parent}
                                    previous={index > 0 ? this.state.children[index - 1] : null}
                                    onChange={this.handleResearchAnswersChange(child)}
                                    hidden={this.state.selectedChildIndex !== index}
                                    didSubmit={this.state.didSubmit}
                                    refs={this.references[index]}
                                    errors={this.state.errors}/>
                            }
                            {!this.isSecondVoucher(child.details) && this.state.voucherType === VoucherTypeCK &&
                                <CreativeKidsResearchQuestions id="research-questions" child={child}
                                    parent={this.props.parent}
                                    previous={index > 0 ? this.state.children[index - 1] : null}
                                    onChange={this.handleResearchAnswersChange(child)}
                                    hidden={this.state.selectedChildIndex !== index}
                                    didSubmit={this.state.didSubmit}
                                    refs={this.references[index]}
                                    errors={this.state.errors}
                                />
                            }
                        </div>
                        )}

                        {(this.state.children.length === 1 || (this.state.selectedChildIndex >= this.state.children.length - 1)) &&
                        <div>

                            {consentRequired &&
                            <div style={{ marginTop: '-0.75rem' }}>
                                <StyledCheckboxRow name={'contact-consent'}
                                    checked={this.state.contactConsent}
                                    onChange={this.updateConsent}
                                    extraStyles={'section'}
                                    label={<span>I agree to being contacted by the {voucherTypeDetails.agencyName} to
                                                       participate in further research to determine the effectiveness of the
                                        program. I understand that I am free to withdraw my consent at any time.</span> }/>
                            </div>}

                            {!this.showNextButton() &&
                            <div style={{ marginTop: '-0.75rem' }} ref={this.references[this.state.selectedChildIndex]?.acceptTerms}>
                                <StyledCheckboxRow name={'accept-terms'}
                                    checked={this.state.acceptTerms}
                                    onChange={this.updateAcceptTerms}
                                    extraStyles={'section'}
                                    label={<span>I agree to the &nbsp;
                                        <a href={this.getTermsAndConditionsUri()} target="_blank"
                                            rel="noopener noreferrer">terms and conditions</a>. <span key="name" className="required_color">*</span><br/>
                                       Our &nbsp; <a href={this.getPrivacyUrl()} target="_blank" rel="noopener noreferrer">
                                       privacy statement</a> &nbsp; explains how we collect and use information.
                                    </span>}/>
                                {this.state.didSubmit && !this.state.acceptTerms && this.state.errors.acceptTerms &&
                                    <p className="empty-error help is-danger terms_conditions">{this.state.errors.acceptTerms ?? ''}</p>}
                            </div>}
                        </div>}

                        <div className="level">
                            {this.showPrevButton() &&
                            <button className="button is-secondary previous-child level-left"
                                onClick={() => this.setState({ selectedChildIndex: this.state.selectedChildIndex - 1 })}>
                                Back
                            </button>}

                            {this.showNextButton() &&
                            <button className="button is-secondary next-child level-right"
                                onClick={this.handleContinue}
                            >
                                Continue
                            </button>}
                            {!this.showNextButton() &&
                                    <ActionButton processing={this.state.spinner}
                                        className={`is-primary ${this.state.children.length > 1 ? ' level-right' : ''}`}
                                        onClick={this.handleSubmit}
                                        text='Submit'/>
                            }

                        </div>

                    </div>
                </div>
            </Section>
        </div>
    }
}

export default connect('transactionContext,parent', actions)(ResearchDataPage)
