import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import SchoolSelector from '../../components/SchoolSelector'
import AsyncSelect from 'react-select'
import '../ResearchQuestions.scss'
import Notification from '../../components/Notification'
import {
    atsiOptions,
    disabilityOptions,
    genderOptions,
    languageOptions,
    hoursOfParticipationOptions,
    creativeActivities
} from '../ResearchDataReferenceData'
import RadioButtonYesNo from '../../components/RadioButtonYesNo'
import { Address } from '@snsw/react-component-library'

class CreativeKidsResearchQuestions extends Component {
    constructor (props) {
        super(props)
        this.state = {
            infoModal: null,
            formValues: {},
            usingDefaultSchool: true,
            usingDefaultLanguage: true
        }
        if (this.prevHasFormValues()) {
            this.state.formValues.school = this.props.previous.formValues.school
            this.state.formValues.schoolSuburb = this.props.previous.formValues.schoolSuburb
            this.state.formValues.mainLanguageAtHome = this.props.previous.formValues.mainLanguageAtHome
        }
        if (this.props.parent && this.props.parent.address) {
            this.state.formValues.address = this.props.parent.address
        }
        this.state.formValues.participationInLast12Months = null
    }

    prevHasFormValues = () => this.props.previous && this.props.previous.formValues

    componentDidUpdate (prevProps, prevState) {
        const newValues = this.postProcessResearchData(this.state, this.state.formValues)
        const prevValues = this.postProcessResearchData(prevState, prevState.formValues)
        if (JSON.stringify(newValues) !== JSON.stringify(prevValues)) {
            this.props.onChange({
                isValid: this.isFormValid(),
                formValues: newValues
            })
        }
        if (this.prevHasFormValues()) {
            if (this.state.usingDefaultSchool &&
                this.state.formValues.school !== this.props.previous.formValues.school) {
                this.setState({
                    formValues: {
                        ...this.state.formValues,
                        school: this.props.previous.formValues.school,
                        schoolSuburb: this.props.previous.formValues.schoolSuburb
                    }
                })
            }
            if (this.state.usingDefaultLanguage &&
                this.state.formValues.mainLanguageAtHome !== this.props.previous.formValues.mainLanguageAtHome) {
                this.setState({
                    formValues: {
                        ...this.state.formValues,
                        mainLanguageAtHome: this.props.previous.formValues.mainLanguageAtHome
                    }
                })
            }
        }
    }

    handleInputChange = name => (event) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [name]: event.target.value
            }
        })
    }

    handlePreviousActivityChange = (event) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                participationInLast12Months: event.target.value,
                previousActivities: event.target.value === 'No' ? '' : this.state.formValues.previousActivities,
                hoursOfParticipation: event.target.value === 'No' ? '' : this.state.formValues.hoursOfParticipation
            }
        })
    }

    handleSchoolChange = (selectedSchool) => {
        this.setState(state => ({
            formValues: {
                ...state.formValues,
                school: selectedSchool ? selectedSchool.name : null,
                schoolSuburb: selectedSchool ? selectedSchool.suburb : null
            },
            usingDefaultSchool: false
        }))
    }

    handleLanguageChange = (selectedOption) => {
        const newValue = Object.prototype.hasOwnProperty.call(selectedOption, 'value')
            ? selectedOption.value
            : selectedOption.label
        this.setState({
            formValues: {
                ...this.state.formValues,
                mainLanguageAtHome: newValue
            },
            usingDefaultLanguage: false
        })
        return newValue
    }

    handleIntendedUseChange = (selectedOption) => {
        const newValue = Object.prototype.hasOwnProperty.call(selectedOption, 'value')
            ? selectedOption.value
            : selectedOption.label
        if (newValue !== 'Don’t know yet') {
            this.setState({
                formValues: {
                    ...this.state.formValues,
                    intendedUseActivity: newValue
                }
            })
        } else {
            this.setState({
                formValues: {
                    ...this.state.formValues,
                    intendedUseActivity: newValue,
                    intendedUseActivityPreviousParticipation: '',
                    intendedUseActivityWouldParticipateWithoutVoucher: ''
                }
            })
        }

        return newValue
    }

    handleSelectChange = name => (selectedOption) => {
        const newValue = Object.prototype.hasOwnProperty.call(selectedOption, 'value')
            ? selectedOption.value
            : selectedOption.label
        this.setState({
            formValues: {
                ...this.state.formValues,
                [name]: newValue
            }
        })
        return newValue
    }

    handleMultiSelectChange = name => (selectedOption) => {
        let newValue = ''
        if (Array.isArray(selectedOption) && selectedOption.length) {
            newValue = selectedOption
                .map(i => Object.prototype.hasOwnProperty.call(i, 'value') ? i.value : i.label)
                .sort()
                .join(';')
        }
        this.setState({
            formValues: {
                ...this.state.formValues,
                [name]: newValue
            }
        })
        return newValue
    }

    // handleAddressChange = name => (address) => {
    //     this.setState(state => ({
    //         formValues: {
    //             ...state.formValues,
    //             [name]: address
    //         }
    //     }))
    // }

    handleAddressChange = (address) => {
        let newAddress = {
            ...address.address,
            streetName: address.address.streetName ? address.address.streetName : address.address.addressLine,
            localityName: address.address.suburb
        }
        const formattedAddress = address.address.formattedAddress ? address.address.formattedAddress
            : this.buildFormattedAddress(newAddress)
        newAddress = {
            ...newAddress,
            formattedAddressString: formattedAddress,
            stateTerritory: address.address.state,
            formattedAddress
        }
        this.setState({
            formValues: {
                ...this.state.formValues,
                address: newAddress
            }
        })
    }

    isPostcodeValid = (postcode) => postcode && !isNaN(postcode) && postcode.length === 4

    isStateValid = (state) => state && isNaN(state) && state.length <= 3

    buildFormattedAddress = (addressParts) => {
        if (!(addressParts.streetName &&
            addressParts.localityName &&
            this.isStateValid(addressParts.state) &&
            this.isPostcodeValid(addressParts.postcode))) {
            return ''
        }

        return (`${addressParts.unitNumber || ''} ` +
            `${addressParts.streetNumber || ''} ${addressParts.streetName || ''} ${addressParts.streetType || ''}`.trim() +
            ', ' +
            `${addressParts.localityName || ''} ${(addressParts.state || '').toUpperCase()} ${addressParts.postcode || ''}`
        ).trim()
    }

    hasAddressError = () => !this.isAddressValid()

    isAddressValid = () => {
        return this.state.formValues.address &&
            this.state.formValues.address.state === 'NSW' &&
            this.state.formValues.address.formattedAddress
    }

    postProcessResearchData = (state, formValues) => {
        return formValues
    }

    isFieldEmpty = (field) => this.props.didSubmit && this.props?.errors[field] && !this.state.formValues[field]

    isValidAddress = (address) =>
        address && address.formattedAddressString &&
        address.stateTerritory && address.localityName && address.streetName

    isFormValid = () => {
        return !!(this.isValidAddress(this.state.formValues.address) &&
            this.state.formValues.school &&
            this.state.formValues.gender &&
            this.state.formValues.mainLanguageAtHome &&
            this.state.formValues.atsiStatus &&
            this.state.formValues.disability &&
            ((this.intendedUseKnown() &&
                this.state.formValues.intendedUseActivityPreviousParticipation &&
                this.state.formValues.intendedUseActivityWouldParticipateWithoutVoucher) ||
               !this.intendedUseKnown()) &&
            ((this.state.formValues.participationInLast12Months === 'Yes' &&
                this.state.formValues.previousActivities &&
                this.state.formValues.hoursOfParticipation) ||
             (this.state.formValues.participationInLast12Months === 'No'))
        )
    }

    intendedUseKnown = () => this.state.formValues.intendedUseActivity !== 'Don’t know yet'

    render () {
        const customPlaceholderStyle = {
            placeholder: (defaultStyles) => {
                return {
                    ...defaultStyles,
                    color: 'black'
                }
            }
        }

        const defaultSchool = (this.prevHasFormValues() && this.props.previous.formValues.school)
            ? { name: this.props.previous.formValues.school, suburb: this.props.previous.formValues.schoolSuburb } : null
        const defaultLanguage = (this.prevHasFormValues() && this.props.previous.formValues.mainLanguageAtHome)
            ? this.props.previous.formValues.mainLanguageAtHome : ''

        // const addressIsDanger = this.isFieldEmpty('address') || Object.keys(this.state.formValues?.address ?? {}).length === 0

        return <div className={`all-questions child-number-${this.props.child ? this.props.child.childSequence : ''}`}
            style={this.props.hidden ? { display: 'none' } : { display: 'block', borderBottom: 'solid 0.125rem #DEE3E5' }}>
            <h2 className="title">Enter some basic information about {this.props.child.details.firstName}</h2>
            {this.props.previous && this.props.previous.details && <Notification type="info"
                message={'To make the process quicker for you, we have pre-filled some information for ' +
                                                                                 `${this.props.child.details.firstName} based on the data you entered for ${this.props.previous.details.firstName}. ` +
                                                                                 'You can change any of the information we have pre-filled.'}
            />}
            <div className="research-question" ref={this.props.refs?.address}>
                {/* <NSWPointAddressEntry */}
                {/*    inputClasses={'input residential-address' + (addressIsDanger ? ' is-danger' : '')} */}
                {/*    value={this.state.formValues.address} */}
                {/*    name={`address${this.props.child ? this.props.child.childSequence : ''}`} */}
                {/*    onChange={this.handleAddressChange('address')} */}
                {/*    fieldLabel="Primary residential address" */}
                {/*    voucherType="CK"/> */}
                <Address inputClasses="input"
                    name={`address${this.props.child ? this.props.child.childSequence : ''}`}
                    apiKey={process.env.REACT_APP_NSWPOINT_V2_API_KEY}
                    value={this.state.formValues.address}
                    address={this.state.formValues.address}
                    onChange={this.handleAddressChange}
                    showErrors={ true }
                    hasError={ this.hasAddressError() }
                    apiState='NSW'
                    errorMessage={ {
                        autoSuggest: 'You must select or enter an address in NSW',
                        suburb: 'The suburb is wrong.',
                        state: 'The AK vouchers are only available to parents, guardians and carers living in NSW.',
                        postcode: 'The postcode is wrong.',
                        addressLine: 'The street address is wrong.'
                    } }
                    addressLabel='Primary residential address'/>
            </div>

            <div className="research-question" ref={this.props.refs?.school}>
                <label className="label">School <span key="name" className="required_color">*</span></label>
                <SchoolSelector id="school"
                    defaultValue={defaultSchool}
                    onChange={this.handleSchoolChange}
                    isError={this.isFieldEmpty('school')}/>
                {this.isFieldEmpty('school') &&
                <p className="empty-error help is-danger">{this.props.errors.school ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.gender}>
                <label className="label" htmlFor="gender">Gender <span key="name" className="required_color">*</span></label>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('gender') ? ' is-danger' : '')} id="gender" placeholder=""
                    isOptionSelected={() => false}
                    options={genderOptions}
                    onChange={this.handleSelectChange('gender')}
                />

                {this.isFieldEmpty('gender') &&
                <p className="empty-error help is-danger">{this.props.errors.gender ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.mainLanguageAtHome}>
                <label className="label" htmlFor="mainLanguageAtHome">Main language spoken at
                    home <span key="name" className="required_color">*</span></label>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('mainLanguageAtHome') ? ' is-danger' : '')} id="mainLanguageAtHome" placeholder={defaultLanguage}
                    isOptionSelected={() => false}
                    options={languageOptions}
                    onChange={this.handleLanguageChange}
                    styles={customPlaceholderStyle}
                />
                {this.isFieldEmpty('mainLanguageAtHome') &&
                <p className="empty-error help is-danger">{this.props.errors.mainLanguageAtHome ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.atsiStatus}>
                <label className="label" htmlFor="atsiStatus">Is this child of Aboriginal or Torres
                    Strait
                    Islander
                    heritage? <span key="name" className="required_color">*</span></label>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('atsiStatus') ? ' is-danger' : '')} id="atsiStatus" placeholder=""
                    isOptionSelected={() => false}
                    options={atsiOptions}
                    onChange={this.handleSelectChange('atsiStatus')}
                />
                {this.isFieldEmpty('atsiStatus') &&
                <p className="empty-error help is-danger">{this.props.errors.atsiStatus ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.disability}>
                <label className="label" htmlFor="disability">Does this child have a disability? <span key="name" className="required_color">*</span></label>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('disability') ? ' is-danger' : '')} id="disability" placeholder=""
                    isOptionSelected={() => false}
                    options={disabilityOptions}
                    onChange={this.handleSelectChange('disability')}
                />
                {this.isFieldEmpty('disability') &&
                <p className="empty-error help is-danger">{this.props.errors.disability ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.intendedUseActivity}>
                <label className="label" htmlFor="intendedUseActivity">What creative or cultural activity will this
                    voucher be used for? <span key="name" className="required_color">*</span></label>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('intendedUseActivity') ? ' is-danger' : '')} id="intendedUseActivity" placeholder=""
                    isOptionSelected={() => false}
                    options={creativeActivities}
                    onChange={this.handleIntendedUseChange}
                />
                {this.isFieldEmpty('intendedUseActivity') &&
                <p className="empty-error help is-danger">{this.props.errors.intendedUseActivity ?? ''}</p>}
            </div>

            {this.state.formValues.intendedUseActivity && this.intendedUseKnown() &&
                <Fragment>
                    <div className="research-question" ref={this.props.refs?.intendedUseActivityPreviousParticipation}>
                        <RadioButtonYesNo label="Has this child participated in this activity before?"
                            id="intendedUseActivityPreviousParticipation"
                            name={`intendedUseActivityPreviousParticipation-${this.props.child.childSequence}`}
                            value={this.state.formValues.intendedUseActivityPreviousParticipation}
                            onChange={this.handleInputChange('intendedUseActivityPreviousParticipation')}
                        />
                        {this.isFieldEmpty('intendedUseActivityPreviousParticipation') &&
                         <p className="empty-error help is-danger">{this.props.errors.intendedUseActivityPreviousParticipation ?? ''}</p>}
                    </div>
                    <div className="research-question" ref={this.props.refs?.intendedUseActivityWouldParticipateWithoutVoucher}>
                        <RadioButtonYesNo label="Would this child have participated in this activity without the voucher?"
                            id="intendedUseActivityWouldParticipateWithoutVoucher"
                            name={`intendedUseActivityWouldParticipateWithoutVoucher-${this.props.child.childSequence}`}
                            value={this.state.formValues.intendedUseActivityWouldParticipateWithoutVoucher}
                            onChange={this.handleInputChange('intendedUseActivityWouldParticipateWithoutVoucher')}
                        />
                        {this.isFieldEmpty('intendedUseActivityWouldParticipateWithoutVoucher') &&
                         <p className="empty-error help is-danger">{this.props.errors.intendedUseActivityWouldParticipateWithoutVoucher ?? ''}</p>}
                    </div>
                </Fragment>
            }

            <div className="research-question" ref={this.props.refs?.participationInLast12Months}>
                <RadioButtonYesNo label="In the last 12 months, has this child participated in a creative or cultural activity?"
                    id="participationInLast12Months"
                    name={`participationInLast12Months-${this.props.child.childSequence}`}
                    value={this.state.formValues.participationInLast12Months}
                    onChange={this.handlePreviousActivityChange}
                />
                {this.isFieldEmpty('participationInLast12Months') &&
                    <p className="empty-error help is-danger">{this.props.errors.participationInLast12Months ?? ''}</p>}
            </div>

            {this.state.formValues.participationInLast12Months === 'Yes' &&
            <Fragment>
                <div className="research-question" ref={this.props.refs?.previousActivities}>
                    <label className="label" htmlFor="previousActivities">
                        In the last 12 months, what creative or cultural activities has this child participated in?  <span key="name" className="required_color">*</span>
                    </label>
                    <AsyncSelect isMulti isClearable={true} className={'styled-async-select' + (this.isFieldEmpty('previousActivities') ? ' is-danger' : '')} id="previousActivities"
                        placeholder=""
                        options={creativeActivities.filter(item => item.group === undefined).filter(item => item.label !== 'Don\'t know yet')}
                        onChange={this.handleMultiSelectChange('previousActivities')}
                    />
                    {this.isFieldEmpty('previousActivities') &&
                        <p className="empty-error help is-danger">{this.props.errors.previousActivities ?? ''}</p>}
                </div>

                <div className="research-question" ref={this.props.refs?.hoursOfParticipation}>
                    <label className="label" htmlFor="hoursOfParticipation">
                        On average, how many hours a week does this child participate in a creative or cultural activity?  <span key="name" className="required_color">*</span>
                    </label>
                    <div className="is-italic is-size-7 label-description">
                        <span>This could be made up of different activities including dance or gaming at lunchtime or formal lessons.</span>
                    </div>
                    <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('hoursOfParticipation') ? ' is-danger' : '')} id="hoursOfParticipation" placeholder=""
                        isOptionSelected={() => false}
                        options={hoursOfParticipationOptions}
                        onChange={this.handleSelectChange('hoursOfParticipation')}
                    />
                    {this.isFieldEmpty('hoursOfParticipation') &&
                        <p className="empty-error help is-danger">{this.props.errors.hoursOfParticipation ?? ''}</p>}
                </div>
            </Fragment>
            }
        </div>
    }
}

CreativeKidsResearchQuestions.propTypes = {
    onChange: PropTypes.func.isRequired,
    child: PropTypes.object.isRequired,
    parent: PropTypes.object,
    hidden: PropTypes.bool,
    previous: PropTypes.object,
    didSubmit: PropTypes.bool,
    refs: PropTypes.any,
    errors: PropTypes.any
}

export default CreativeKidsResearchQuestions
