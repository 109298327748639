import React, { Fragment } from 'react'
import SelfEsteemIcon from '../../icons/AK_Voucher_Icons_01_Self-Esteem_Circle.svg'
import LeadershipSkillsIcon from '../../icons/AK_Voucher_Icons_02_Leadership_Circle.svg'
import ResilienceIcon from '../../icons/AK_Voucher_Icons_03_Resilience_Circle.svg'
import ProblemSolvingIcon from '../../icons/AK_Voucher_Icons_04_ProblemSolving_Circle.svg'

function ActiveKidsResearchDataInfoPanel () {
    return <Fragment>
        <div className="columns is-mobile research-info-icons is-multiline">
            <div className="column is-half-mobile">
                <img alt="Improved self-esteem" src={SelfEsteemIcon} />
                <div className="icon-label">Improved <br/> self-esteem</div>
            </div>
            <div className="column is-half-mobile">
                <img alt="Leadership skills" src={LeadershipSkillsIcon} />
                <div className="icon-label">Leadership skills</div>
            </div>
            <div className="column is-half-mobile">
                <img alt="Resilience" src={ResilienceIcon} />
                <div className="icon-label">Resilience</div>
            </div>
            <div className="column is-half-mobile">
                <img alt="Problem solving skills" src={ProblemSolvingIcon} />
                <div className="icon-label">Problem-solving skills</div>
            </div>
        </div>
        <div style={{ marginBottom: '2rem' }}>

            <p style={{ marginBottom: '1.5rem' }}>
                <span key="name" className="required_color">*</span> {' '} indicates a required field
            </p>
            <p style={{ marginBottom: '1.5rem', fontWeight: 'bold' }}>
                Gathering data about the children who participate in this program allows the NSW
                Government to measure the benefits of regular exercise.
            </p>
            <p style={{ marginBottom: '1.5rem' }}>
                By providing some basic information you’re helping us work out how effective the
                program can be. This will allow us to continue offering programs that will directly
                benefit your child.
            </p>
            <p style={{ marginBottom: '0.5rem' }}><b>Privacy Policy</b></p>
            <p style={{ marginBottom: '1.5rem' }}>
                The data you provide is confidential and won’t be linked to your, or your child’s, name.
                Providing, or choosing not to provide, this information won’t affect your eligibility
                for an Active Kids voucher. It will simply be used to measure how effective the program
                is.
            </p>
        </div>
    </Fragment>
}

export default ActiveKidsResearchDataInfoPanel
