import React, { Fragment } from 'react'

function CreativeKidsResearchDataInfoPanel () {
    return <Fragment>
        <div style={{ marginBottom: '2rem' }}>
            <p style={{ marginBottom: '1.5rem' }}>
                <span key="name" className="required_color">*</span> {' '} indicates a required field
            </p>
            <p style={{ marginBottom: '1.5rem', fontWeight: 'bold' }}>
                Gathering data about the children who participate in this program allows Create NSW
                to measure the benefits of creative and cultural lessons or activities.
            </p>
            <p style={{ marginBottom: '1.5rem' }}>
                By providing some basic information you’re helping us work out how effective the
                program can be. This will allow us to continue offering programs that will directly
                benefit your children.
            </p>
            <p style={{ marginBottom: '0.5rem' }}><b>Privacy Policy</b></p>
            <p style={{ marginBottom: '1.5rem' }}>
                The data you provide is confidential and won’t be linked to your, or your child’s, name.
                Providing, or choosing not to provide, this information won’t affect your eligibility
                for a Creative Kids voucher. It will simply be used to measure how effective the program
                is.
            </p>
        </div>
    </Fragment>
}

export default CreativeKidsResearchDataInfoPanel
