import React, { Component } from 'react'
import AuthClient from './AuthClient'
import Spinner from './Spinner'
import PropTypes from 'prop-types'
import { getPathBaseFromVoucherType, getVoucherTypeFromPath } from '../VoucherTypeDetails'

class AuthCallbackPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired
    }

    componentDidMount () {
        const voucherType = getVoucherTypeFromPath(this.props.location.pathname)
        if (AuthClient.processAuthCallback(this.props.location)) {
            this.props.history.push({ pathname: `/${getPathBaseFromVoucherType(voucherType)}/parent` })
        } else {
            this.props.history.push({ pathname: '/error' })
        }
    }

    render () {
        return <Spinner />
    }
}

export default AuthCallbackPage
