
export const SchoolApi = {
    getAllSchools: async () => {
        const authToken = window.sessionStorage.getItem('authToken')
        if (!authToken) {
            throw new Error('Not authenticated')
        }

        const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/asl/schools`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`
            }

        })
        if (response.status === 200) {
            return response.json()
        } else {
            throw new Error('Error while retrieving schools data')
        }
    },
    findSchools: async (name) => {
        const authToken = window.sessionStorage.getItem('authToken')
        if (!authToken) {
            throw new Error('Not authenticated')
        }

        const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/asl/schools/${name}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
        if (response.status === 200) {
            return response.json()
        } else {
            throw new Error('Error while retrieving schools data')
        }
    }
}
