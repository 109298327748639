import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Section from '../layout/ProgressSection'
import { getPathBase } from '../VoucherTypeDetails'

class ErrorPage extends Component {
    handleClick = () => {
        const target = `/${getPathBase(this.props.location.pathname)}/claim`
        console.log('Try Again URL :', target)
        this.props.history.push(target)
    }

    render () {
        return <Section style={{ height: '20rem' }}>
            <h1>A system error has occurred</h1>
            <p>
                Sorry, due to a system error, we can't process your request. Please try again.
            </p>
            <button className="button is-primary" onClick={this.handleClick}>Try Again</button>
        </Section>
    }
}

ErrorPage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}
export default withRouter(ErrorPage)
