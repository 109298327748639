import React from 'react'
import MedicareCardSampleIrn from './medicare-card-sample-irn.png'
import PropTypes from 'prop-types'
import Modal from '../components/Modal'

const IrnInfoModal = ({ closeModal }) => {
    return <Modal title='Individual reference number (IRN)'
        closeModal={closeModal}
        body={<div>
            <p>
                          The Individual Reference Number (IRN) is a number between 1–9 to the left
                          of <strong>your child’s name</strong> on your Medicare Card.
            </p>
            <div className="level" style={{
                paddingTop: '3rem',
                paddingBottom: '5rem'
            }}>
                <div className='level-item has-centered-text'>
                    <img src={MedicareCardSampleIrn} alt="medicare card" className="is-centered"
                        style={{ justifySelf: 'center' }}/>
                </div>
            </div>
        </div>
        }
    />
}

IrnInfoModal.propTypes = {
    closeModal: PropTypes.func.isRequired
}

export default IrnInfoModal
