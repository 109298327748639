import { VoucherTypeAK } from '../VoucherTypeDetails'
import { v4 as uuidv4 } from 'uuid'

const AuthClient = {
    assertLoggedInAndGetEmail: async () => {
        let user = {}
        const authToken = window.sessionStorage.getItem('authToken')
        if (!authToken) {
            throw new Error('Not authenticated')
        }

        const response = await fetch(process.env.REACT_APP_USER_INFO_ENDPOINT, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })

        if (response.status === 200) {
            user = await response.json()
            if (response.headers.get('x-csrf-token')) {
                window.sessionStorage.setItem('x-csrf-token', response.headers.get('x-csrf-token'))
            }
        } else {
            throw new Error('Not authenticated')
        }

        return user.email
    },
    appendAuthHeaders: appendAuthHeaders,
    initiateAuthFlow: (voucherType) => {
        const clientId = voucherType === VoucherTypeAK ? process.env.REACT_APP_AUTH_CLIENT_ID : process.env.REACT_APP_AUTH_CK_CLIENT_ID
        const scope = encodeURIComponent('openid email profile')
        window.open(`${process.env.REACT_APP_AUTH_ENDPOINT}?client_id=${clientId}&scope=${scope}&response_type=token`, '_self')
    },
    clearCorrelationId: () => window.sessionStorage.setItem('correlation-id', ''),
    getCorrelationId: getCorrelationId,
    processAuthCallback: (location) => {
        let token = null
        const callbackData = location.hash.split('#')[1]
        const items = callbackData.split('&')
        if (items[0].split('=')[0] === 'access_token') {
            token = items[0].split('=')[1]
        }
        window.sessionStorage.setItem('authToken', token)
        return !!token
    }
}

function getCorrelationId () {
    let correlationId = window.sessionStorage.getItem('correlation-id')
    if (!correlationId) {
        correlationId = uuidv4()
        window.sessionStorage.setItem('correlation-id', correlationId)
    }
    return correlationId
}

function appendAuthHeaders (headers) {
    const authToken = window.sessionStorage.getItem('authToken')
    if (sessionStorage.getItem('x-csrf-token')) {
        headers['x-csrf-token'] = sessionStorage.getItem('x-csrf-token')
    }
    headers['correlation-id'] = getCorrelationId()
    headers.Authorization = `Bearer ${authToken}`
    return headers
}

export default AuthClient
