import React, { Fragment } from 'react'
import './CreativeVoucherUsagePanel.scss'

function CreativeVoucherUsagePanel () {
    return <Fragment>
        <div className="ck-voucher-usage">
            <h2>You may use your Creative Kids voucher on:</h2>
            <div className="columns">
                <div className="column is-one-third">
                    <h3>Visual Arts</h3>
                    <ul>
                        <li>Drawing</li>
                        <li>Sculpture</li>
                        <li>Sketching</li>
                        <li>Painting</li>
                        <li>Print-making</li>
                        <li>Advertising</li>
                        <li>Public art</li>
                        <li>Media arts</li>
                        <li>Design</li>
                        <li>Multimedia</li>
                        <li>Craft</li>
                        <li>Screen art</li>
                    </ul>
                </div>
                <div className="column is-one-third">
                    <h3>Performing arts</h3>
                    <ul>
                        <li>Music</li>
                        <li>Drama</li>
                        <li>Singing</li>
                        <li>Circus acts</li>
                        <li>Choreography</li>
                        <li>Parkour</li>
                        <li>Puppetry</li>
                        <li>Theatre</li>
                        <li>Dance</li>
                        <li>Other performing arts</li>
                        <li>Other visual arts</li>
                    </ul>
                </div>
                <div className="column is-one-third">
                    <h3>Creative industries</h3>
                    <ul>
                        <li>Architecture</li>
                        <li>Coding</li>
                        <li>Game design</li>
                        <li>Industrial design</li>
                        <li>Graphic design</li>
                        <li>Fashion design</li>
                        <li>Radio</li>
                        <li>Robotics</li>
                        <li>Augmented/virtual reality</li>
                    </ul>
                </div>
            </div>
            <div className="columns">
                <div className="column is-one-third">
                    <h3>Literary and linguistic arts</h3>
                    <ul>
                        <li>Creative writing</li>
                        <li>Debating</li>
                        <li>Languages</li>
                        <li>Publishing</li>
                        <li>Public speaking</li>
                        <li>Poetry</li>
                        <li>Slam poetry</li>
                        <li>Spoken Word</li>
                    </ul>
                </div>
                <div className="column is-two-thirds">
                    <h3>Cultural experiences</h3>
                    <p>
                        You may also use your voucher on workshop and performance activities in line with the
                        structured program requirements associated with multicultural communities.
                    </p>
                </div>
            </div>
        </div>
    </Fragment>
}

export default CreativeVoucherUsagePanel
