import { Route } from 'react-router-dom'
import ErrorPage from './components/ErrorPage'
import React from 'react'
import { connect } from 'unistore/react'
import PropTypes from 'prop-types'

export class ProtectedRoute extends React.Component {
    static propTypes = {
        component: PropTypes.func.isRequired,
        parent: PropTypes.object
    }

    render () {
        const parent = this.props.parent
        return (parent && parent.name) ? <Route {...this.props}/> : <ErrorPage/>
    }
}

export default connect('parent')(ProtectedRoute)
