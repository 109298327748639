import React from 'react'
import StyledCheckbox from './StyledCheckbox'
import PropTypes from 'prop-types'

export const StyledCheckboxRow = (props) => <div className={`columns is-mobile ${props.extraStyles || ''}`}
    style={{ paddingBottom: 0, marginBottom: '1.75rem', marginTop: '0' }}>
    <div className='column is-narrow'>
        <StyledCheckbox
            checked={props.checked}
            name={props.name}
            onChange={props.onChange}
        />
    </div>

    <div className="column">
        <label htmlFor={props.name}>
            { props.label }
        </label>
    </div>
</div>

StyledCheckboxRow.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    extraStyles: PropTypes.string
}
