module.exports = function (t) {
    // eslint-disable-next-line no-return-assign,no-sequences
    return t = t || 'unistorePersist', {
        getState: function () {
            var e
            try {
                e = JSON.parse(sessionStorage.getItem(t))
            } catch (t) {}
            if (e !== null) return e
        },
        setState: function (e) {
            try {
                sessionStorage.setItem(t, JSON.stringify(e))
            } catch (t) {}
        },
        clearState: function () {
            try {
                sessionStorage.removeItem(t)
            } catch (t) {}
        }
    }
}
