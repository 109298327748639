import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SchoolSelector from '../../components/SchoolSelector'
import AsyncSelect from 'react-select'
import HeightAndWeightInfoModal from './HeightAndWeightInfoModal'
import OrganisedSportSessionsInfoModal from './OrganisedSportSessionsInfoModal'
import PhysicallyActiveInfoModal from './PhysicallyActiveInfoModal'
import '../ResearchQuestions.scss'
import { atsiOptions, disabilityOptions, genderOptions, languageOptions } from '../ResearchDataReferenceData'
import Notification from '../../components/Notification'
import { Address } from '@snsw/react-component-library'

class ActiveKidsResearchQuestions extends Component {
    constructor (props) {
        super(props)
        this.state = {
            infoModal: null,
            formValues: {},
            preferNotToSayHeightAndWeight: false,
            usingDefaultSchool: true,
            usingDefaultLanguage: true
        }
        if (this.prevHasFormValues()) {
            this.state.formValues.school = this.props.previous.formValues.school
            this.state.formValues.schoolSuburb = this.props.previous.formValues.schoolSuburb
            this.state.formValues.mainLanguageAtHome = this.props.previous.formValues.mainLanguageAtHome
        }
        if (this.props.parent && this.props.parent.address) {
            this.state.formValues.address = this.props.parent.address
        }
    }

    prevHasFormValues = () => this.props.previous && this.props.previous.formValues

    componentDidUpdate (prevProps, prevState) {
        const newValues = this.postProcessResearchData(this.state, this.state.formValues)
        const prevValues = this.postProcessResearchData(prevState, prevState.formValues)
        if (JSON.stringify(newValues) !== JSON.stringify(prevValues)) {
            this.props.onChange({
                isValid: this.isFormValid(),
                formValues: newValues
            })
        }
        if (this.prevHasFormValues()) {
            if (this.state.usingDefaultSchool &&
                this.state.formValues.school !== this.props.previous.formValues.school) {
                this.setState({
                    formValues: {
                        ...this.state.formValues,
                        school: this.props.previous.formValues.school,
                        schoolSuburb: this.props.previous.formValues.schoolSuburb
                    }
                })
            }
            if (this.state.usingDefaultLanguage &&
                this.state.formValues.mainLanguageAtHome !== this.props.previous.formValues.mainLanguageAtHome) {
                this.setState({
                    formValues: {
                        ...this.state.formValues,
                        mainLanguageAtHome: this.props.previous.formValues.mainLanguageAtHome
                    }
                })
            }
        }
    }

    handleInputChange = name => (event) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [name]: event.target.value
            }
        })
    }

    handleSchoolChange = (selectedSchool) => {
        this.setState(state => ({
            formValues: {
                ...state.formValues,
                school: selectedSchool ? selectedSchool.name : null,
                schoolSuburb: selectedSchool ? selectedSchool.suburb : null
            },
            usingDefaultSchool: false
        }))
    }

    handleLanguageChange = (selectedOption) => {
        const newValue = Object.prototype.hasOwnProperty.call(selectedOption, 'value')
            ? selectedOption.value
            : selectedOption.label
        this.setState({
            formValues: {
                ...this.state.formValues,
                mainLanguageAtHome: newValue
            },
            usingDefaultLanguage: false
        })
        return newValue
    }

    handleSelectChange = name => (selectedOption) => {
        const newValue = Object.prototype.hasOwnProperty.call(selectedOption, 'value')
            ? selectedOption.value
            : selectedOption.label
        this.setState({
            formValues: {
                ...this.state.formValues,
                [name]: newValue
            }
        })
        return newValue
    }

    // handleAddressChange = name => (address) => {
    //     this.setState(state => ({
    //         formValues: {
    //             ...state.formValues,
    //             [name]: address
    //         }
    //     }))
    // }

    handleAddressChange = (address) => {
        let newAddress = {
            ...address.address,
            streetName: address.address.streetName ? address.address.streetName : address.address.addressLine,
            localityName: address.address.suburb
        }
        const formattedAddress = address.address.formattedAddress ? address.address.formattedAddress
            : this.buildFormattedAddress(newAddress)
        newAddress = {
            ...newAddress,
            formattedAddressString: formattedAddress,
            stateTerritory: address.address.state,
            formattedAddress
        }
        this.setState({
            formValues: {
                ...this.state.formValues,
                address: newAddress
            }
        })
    }

    isPostcodeValid = (postcode) => postcode && !isNaN(postcode) && postcode.length === 4

    isStateValid = (state) => state && isNaN(state) && state.length <= 3

    buildFormattedAddress = (addressParts) => {
        if (!(addressParts.streetName &&
            addressParts.localityName &&
            this.isStateValid(addressParts.state) &&
            this.isPostcodeValid(addressParts.postcode))) {
            return ''
        }

        return (`${addressParts.unitNumber || ''} ` +
            `${addressParts.streetNumber || ''} ${addressParts.streetName || ''} ${addressParts.streetType || ''}`.trim() +
            ', ' +
            `${addressParts.localityName || ''} ${(addressParts.state || '').toUpperCase()} ${addressParts.postcode || ''}`
        ).trim()
    }

    hasAddressError = () => !this.isAddressValid()

    isAddressValid = () => {
        return this.state.formValues.address &&
            this.state.formValues.address.state === 'NSW' &&
            this.state.formValues.address.formattedAddress
    }

    handlePreferNotToSayHeightAndWeight = (value) => {
        this.setState({ preferNotToSayHeightAndWeight: value })
    }

    postProcessResearchData = (state, formValues) => {
        if (state.preferNotToSayHeightAndWeight) {
            formValues.height = '-1'
            formValues.weight = '-1'
        }
        return formValues
    }

    showErrorWeight = () => this.state.formValues.weight &&
        !this.state.preferNotToSayHeightAndWeight &&
        !this.isValidWeight()

    showErrorHeight = () => this.state.formValues.height &&
        !this.state.preferNotToSayHeightAndWeight &&
        !this.isValidHeight()

    showErrorEmptyWeight = () => this.props.didSubmit &&
        !this.state.formValues.weight &&
        !this.state.preferNotToSayHeightAndWeight

    showErrorEmptyHeight = () => this.props.didSubmit &&
        !this.state.formValues.height &&
        !this.state.preferNotToSayHeightAndWeight

    isFieldEmpty = (field) => this.props.didSubmit && this.props?.errors[field] && !this.state.formValues[field]

    isIntegerWithinRange = (value, min, max) => value &&
        value.toLowerCase().indexOf('e') === -1 &&
        value.indexOf('.') === -1 &&
        Number(value) === Math.floor(Number(value)) &&
        Number(value) > min &&
        Number(value) <= max

    isValidWeight = () => this.isIntegerWithinRange(this.state.formValues.weight, 10, 150)
    isValidHeight = () => this.isIntegerWithinRange(this.state.formValues.height, 80, 225)

    isValidAddress = (address) =>
        address && address.formattedAddressString &&
         address.stateTerritory && address.localityName && address.streetName

    isFormValid = () =>
        !!(this.isValidAddress(this.state.formValues.address) &&
            this.state.formValues.school &&
            this.state.formValues.gender &&
            this.state.formValues.mainLanguageAtHome &&
            this.state.formValues.atsiStatus &&
            this.state.formValues.disability &&
            (this.state.preferNotToSayHeightAndWeight ||
                (this.isValidHeight() && this.isValidWeight())) &&
            this.state.formValues.activityDays &&
            this.state.formValues.activitySessionsPerYear &&
            this.state.formValues.expectedActivity)

    render () {
        const customPlaceholderStyle = {
            placeholder: (defaultStyles) => {
                return {
                    ...defaultStyles,
                    color: 'black'
                }
            }
        }

        const defaultSchool = (this.prevHasFormValues() && this.props.previous.formValues.school)
            ? { name: this.props.previous.formValues.school, suburb: this.props.previous.formValues.schoolSuburb } : null
        const defaultLanguage = (this.prevHasFormValues() && this.props.previous.formValues.mainLanguageAtHome)
            ? this.props.previous.formValues.mainLanguageAtHome : ''

        // const addressIsDanger = this.isFieldEmpty('address') || Object.keys(this.state.formValues?.address ?? {}).length === 0

        return <div className={`all-questions child-number-${this.props.child ? this.props.child.childSequence : ''}`}
            style={this.props.hidden ? { display: 'none' } : { display: 'block' }} >
            <h2 className="title">Enter some basic information about {this.props.child.details.firstName}</h2>
            {this.props.previous && this.props.previous.details && <Notification type="info"
                message={'To make the process quicker for you, we have pre-filled some information for ' +
                `${this.props.child.details.firstName} based on the data you entered for ${this.props.previous.details.firstName}. ` +
                'You can change any of the information we have pre-filled.'}
            />}
            <div className="research-question" ref={this.props.refs?.address}>
                {/* <NSWPointAddressEntry */}
                {/*    inputClasses={'input residential-address' + (addressIsDanger ? ' is-danger' : '')} */}
                {/*    value={this.state.formValues.address} */}
                {/*    name={`address${this.props.child ? this.props.child.childSequence : ''}`} */}
                {/*    onChange={this.handleAddressChange('address')} */}
                {/*    fieldLabel="Primary residential address" */}
                {/*    voucherType="AK"/> */}
                <Address inputClasses="input"
                    name={`address${this.props.child ? this.props.child.childSequence : ''}`}
                    apiKey={process.env.REACT_APP_NSWPOINT_V2_API_KEY}
                    value={this.state.formValues.address}
                    address={this.state.formValues.address}
                    onChange={this.handleAddressChange}
                    showErrors={ true }
                    hasError={ this.hasAddressError() }
                    apiState='NSW'
                    errorMessage={ {
                        autoSuggest: 'You must select or enter an address in NSW',
                        suburb: 'The suburb is wrong.',
                        state: 'The AK vouchers are only available to parents, guardians and carers living in NSW.',
                        postcode: 'The postcode is wrong.',
                        addressLine: 'The street address is wrong.'
                    } }
                    addressLabel='Primary residential address'/>
            </div>

            <div className="research-question" ref={this.props.refs?.school}>
                <label className="label">School <span key="name" className="required_color">*</span></label>
                <SchoolSelector id="school"
                    defaultValue={defaultSchool}
                    onChange={this.handleSchoolChange}
                    isError={this.isFieldEmpty('school')}/>
                {this.isFieldEmpty('school') &&
                <p className="empty-error help is-danger">{this.props.errors.school ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.gender}>
                <label className="label" htmlFor="gender">Gender <span key="name" className="required_color">*</span></label>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('gender') ? ' is-danger' : '')}
                    id="gender" placeholder=""
                    isOptionSelected={() => false}
                    options={genderOptions}
                    onChange={this.handleSelectChange('gender')}
                />
                {this.isFieldEmpty('gender') &&
                    <p className="empty-error help is-danger">{this.props.errors.gender ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.mainLanguageAtHome}>
                <label className="label" htmlFor="mainLanguageAtHome">Main language spoken at
                    home <span key="name" className="required_color">*</span></label>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('mainLanguageAtHome') ? ' is-danger' : '')}
                    id="mainLanguageAtHome" placeholder={defaultLanguage}
                    isOptionSelected={() => false}
                    options={languageOptions}
                    onChange={this.handleLanguageChange}
                    styles = {customPlaceholderStyle}
                />
                {this.isFieldEmpty('mainLanguageAtHome') &&
                    <p className="empty-error help is-danger">{this.props.errors.mainLanguageAtHome ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.atsiStatus}>
                <label className="label" htmlFor="atsiStatus">Is this child of Aboriginal or Torres
                    Strait
                    Islander
                    heritage? <span key="name" className="required_color">*</span></label>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('atsiStatus') ? ' is-danger' : '')} id="atsiStatus" placeholder=""
                    isOptionSelected={() => false}
                    options={atsiOptions}
                    onChange={this.handleSelectChange('atsiStatus')}
                />
                {this.isFieldEmpty('atsiStatus') &&
                    <p className="empty-error help is-danger">{this.props.errors.atsiStatus ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.disability}>
                <label className="label" htmlFor="disability">Does this child have a disability? <span key="name" className="required_color">*</span></label>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('disability') ? ' is-danger' : '')} id="disability" placeholder=""
                    isOptionSelected={() => false}
                    options={disabilityOptions}
                    onChange={this.handleSelectChange('disability')}
                />
                {this.isFieldEmpty('disability') &&
                    <p className="empty-error help is-danger">{this.props.errors.disability ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.height}>
                <label className="label label-with-description" htmlFor="height"
                    style={{ marginBottom: 0 }}>Height in cm (please be as accurate as
                    possible) <span key="name" className="required_color">*</span></label>
                <div className="is-italic is-size-7 label-description">
                    <span>For research purposes only; this data will not be linked to your child. </span>
                    <span className='has-text-link height-help find-out-more'
                        onClick={() => this.setState({ infoModal: 'heightAndWeight' })}>Find out more</span>
                </div>
                {this.state.preferNotToSayHeightAndWeight &&
                <p className="input prefer-not-to-say-height-message">Prefer not to say. </p>}
                {!this.state.preferNotToSayHeightAndWeight &&
                <input className={'input' + (this.showErrorHeight() || this.showErrorEmptyHeight() ? ' is-danger' : '')} name="height"
                    onChange={this.handleInputChange('height')}/>
                }
                {this.showErrorHeight() && <p className="height-error help is-danger">
                    Height (in cm) must be a whole number more than 80 and less than or equal to 225.
                </p>}
                {this.isFieldEmpty('height') &&
                    <p className="empty-error help is-danger">{this.props.errors.height ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.weight}>
                <label className="label label-with-description" htmlFor="weight"
                    style={{ marginBottom: 0 }}>Weight in kg (please be as accurate as
                    possible) <span key="name" className="required_color">*</span></label>
                <div className="is-italic is-size-7 label-description">
                    <span>For research purposes only; this data will not be linked to your child. </span>
                    <span className='has-text-link weight-help find-out-more'
                        onClick={() => this.setState({ infoModal: 'heightAndWeight' })}>Find out more</span>
                </div>
                {this.state.preferNotToSayHeightAndWeight &&
                <p className="input prefer-not-to-say-weight-message">Prefer not to say. </p>}
                {!this.state.preferNotToSayHeightAndWeight &&
                <input className={'input' + (this.showErrorWeight() || this.showErrorEmptyWeight() ? ' is-danger' : '')}
                    name="weight"
                    onChange={this.handleInputChange('weight')}/>
                }
                {this.showErrorWeight() && <p className="weight-error help is-danger">
                    Weight must be a whole number more than 10 and less than or equal to 150.
                </p>}
                {this.isFieldEmpty('weight') &&
                    <p className="empty-error help is-danger">{this.props.errors.weight ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.activityDays}>
                <label className="label" htmlFor="activityDays">
                    In a typical week, how many days is this child physically active for at least 60
                    minutes? (including school hours) <span key="name" className="required_color">*</span>
                </label>
                <span className='activityDays-help has-text-link label-link'
                    onClick={() => this.setState({ infoModal: 'physicallyActive' })}>What activities are considered 'physically active'?</span>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('activityDays') ? ' is-danger' : '')} id="activityDays" placeholder=""
                    isOptionSelected={() => false}
                    options={activityDayOptions}
                    onChange={this.handleSelectChange('activityDays')}
                />
                {this.isFieldEmpty('activityDays') &&
                    <p className="empty-error help is-danger">{this.props.errors.activityDays ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.activitySessionsPerYear}>
                <label className="label" htmlFor="activitySessionsPerYear">
                    In the last 12 months, how many sessions of organised sport or physical activity did
                    this child participate in outside of school hours? <span key="name" className="required_color">*</span>
                </label>
                <span className='activitySessionsPerYear-help has-text-link label-link'
                    onClick={() => this.setState({ infoModal: 'organisedSport' })}>What are organised sports or activities?</span>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('activitySessionsPerYear') ? ' is-danger' : '')} id="activitySessionsPerYear" placeholder=""
                    isOptionSelected={() => false}
                    options={this.activitySessionsPerYearOptions}
                    onChange={this.handleSelectChange('activitySessionsPerYear')}
                />
                {this.isFieldEmpty('activitySessionsPerYear') &&
                    <p className="empty-error help is-danger">{this.props.errors.activitySessionsPerYear ?? ''}</p>}
            </div>

            <div className="research-question" ref={this.props.refs?.expectedActivity}>
                <label className="label" htmlFor="expectedActivity">On which activity do you plan on
                    using
                    this
                    voucher? <span key="name" className="required_color">*</span></label>
                <AsyncSelect className={'styled-async-select' + (this.isFieldEmpty('expectedActivity') ? ' is-danger' : '')} id="expectedActivity" placeholder=""
                    isOptionSelected={() => false}
                    options={[{ label: 'Existing activity', value: 'Existing activity' },
                        { label: 'Somewhere new', value: 'Somewhere new' },
                        { label: 'Don\'t know', value: 'Don’t know' }
                    ]}
                    onChange={this.handleSelectChange('expectedActivity')}
                />
                {this.isFieldEmpty('expectedActivity') &&
                    <p className="empty-error help is-danger">{this.props.errors.expectedActivity ?? ''}</p>}
            </div>

            {
                this.state.infoModal === 'heightAndWeight' &&
                <HeightAndWeightInfoModal
                    closeModal={() => this.setState({ infoModal: null })}
                    preferNotToSay={this.state.preferNotToSayHeightAndWeight}
                    changePreferNotToSay={this.handlePreferNotToSayHeightAndWeight}
                />
            }
            {
                this.state.infoModal === 'organisedSport' &&
                <OrganisedSportSessionsInfoModal
                    closeModal={() => this.setState({ infoModal: null })}/>
            }
            {
                this.state.infoModal === 'physicallyActive' &&
                <PhysicallyActiveInfoModal
                    closeModal={() => this.setState({ infoModal: null })}/>
            }
        </div>
    }

    activitySessionsPerYearOptions = [
        { label: '0' },
        { label: '1 - 14' },
        { label: '15 - 23' },
        { label: '24 - 103' },
        { label: '104 - 207' },
        { label: '208 - 311' },
        { label: 'Over 311' },
        { label: 'Not sure' }]
}

const activityDayOptions = [
    { label: '0' },
    { label: '1' },
    { label: '2' },
    { label: '3' },
    { label: '4' },
    { label: '5' },
    { label: '6' },
    { label: '7' },
    { label: 'Not sure', value: '-1' }
]

ActiveKidsResearchQuestions.propTypes = {
    onChange: PropTypes.func.isRequired,
    child: PropTypes.object.isRequired,
    parent: PropTypes.object,
    hidden: PropTypes.bool,
    previous: PropTypes.object,
    didSubmit: PropTypes.bool,
    refs: PropTypes.any,
    errors: PropTypes.object
}

export default ActiveKidsResearchQuestions
