import React from 'react'
import PropTypes from 'prop-types'
import './StyledRadioButton.scss'

const StyledRadioButton = (props) =>
    <div className="StyledRadioButton" onClick={(e) => handleClick(e, props)}>
        <input
            id={props.name}
            name={props.name}
            className="checkbox"
            type="radio"
            value={props.value}
            onChange={props.onChange}
            checked={props.checked}
        />
        <label className="radio-marker" htmlFor={props.name}/>
        <label className="radio-text" htmlFor={props.name}>
            {props.text}
        </label>
    </div>

StyledRadioButton.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

const handleClick = (e, props) => {
    console.log('click', e, props)
    props.onChange({ target: { value: props.value } })
}
export default StyledRadioButton
