import React from 'react'
import PoiClient from '../child-eligibility/PoiClient'
import AuthClient from '../components/AuthClient'
import Spinner from '../components/Spinner'
import { Address } from '@snsw/react-component-library'
import ActionButton from '../components/ActionButton'
import PropTypes from 'prop-types'
import { connect } from 'unistore/react'
import actions from '../actions'
import './ContactDetailsPage.scss'
import PageComponent from '../components/PageComponent'
import StyledCheckbox from '../components/StyledCheckbox'
import InputWithLabelAndErrorMessage from '../components/InputWithLabelAndErrorMessage'
import tuoClient from '../utils/tuoClient'
import ReactGA from 'react-ga'
import {
    getPathBase,
    getPathBaseFromVoucherType,
    getVoucherTypeDetails,
    getVoucherTypeFromPath
} from '../VoucherTypeDetails'
import Section from '../layout/ProgressSection'

export class ContactDetailsPage extends PageComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        parent: PropTypes.object.isRequired,
        setParentData: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)
        this.state = {
            loading: true,
            formValues: {
                contactNumber: '',
                address: {}
            },
            contactConsent: false,
            voucherType: getVoucherTypeFromPath(this.props.location.pathname)
        }
    }

    recordPageView = (name) => {
        try {
            ReactGA.set({ page: `${this.props.location.pathname}/${name}` })
            ReactGA.pageview(`${this.props.location.pathname}/${name}`)
        } catch (err) {
        }
    }

    async componentDidMount () {
        try {
            const voucherTypeDetails = getVoucherTypeDetails(this.state.voucherType)
            const email = await AuthClient.assertLoggedInAndGetEmail()
            const poiResponse = await PoiClient.getPoiStatus(voucherTypeDetails)
            if (poiResponse && poiResponse.verified) {
                this.props.setParentData({ name: poiResponse.name, email })
            } else {
                const redirectUrl = await PoiClient.getRedirectURL(voucherTypeDetails)
                this.recordPageView('poi-redirect')
                window.open(redirectUrl, '_self')
            }
        } catch (error) {
            this.props.history.push({ pathname: `/${getPathBase(this.props.location.pathname)}/error/identity-error` })
        }

        this.prefillFromTuo()
        this.prefillFromVouchers()
    }

    async prefillFromTuo () {
        try {
            tuoClient.getCustomer().then(({ contactNumber, address }) => {
                this.setState((oldState) => {
                    if (oldState.formValues.contactNumber === '') {
                        return {
                            ...oldState,
                            formValues: {
                                ...oldState.formValues,
                                contactNumber,
                                address
                            }
                        }
                    }
                    return oldState
                })
            })
        } catch (e) {
            console.error(e)
        }
    }

    async prefillFromVouchers () {
        try {
            const parentPrefillData = await PoiClient.getParentDetails(this.state.voucherType)
            if (parentPrefillData) {
                if (parentPrefillData.contactNumber) {
                    this.setState({
                        formValues: {
                            contactNumber: parentPrefillData.contactNumber,
                            address: parentPrefillData.address
                        }
                    })
                }
            }
        } catch (error) {
            // potential errors are parent not found 404 if they have not previously allocated a voucher
            console.error(error)
        } finally {
            this.setState({ loading: false })
        }
    }

    handleInputChange = inputName => (event) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [inputName]: event.target.value
            }
        })
    }

    handleAddressChange = (address) => {
        let newAddress = {
            ...address.address,
            streetName: address.address.streetName ? address.address.streetName : address.address.addressLine,
            localityName: address.address.suburb
        }
        const formattedAddress = address.address.formattedAddress ? address.address.formattedAddress
            : this.buildFormattedAddress(newAddress)
        newAddress = {
            ...newAddress,
            formattedAddressString: formattedAddress,
            stateTerritory: address.address.state,
            formattedAddress
        }
        this.setState({
            formValues: {
                ...this.state.formValues,

                address: newAddress
            }
        })
    }

    isPostcodeValid = (postcode) => postcode && !isNaN(postcode) && postcode.length === 4

    isStateValid = (state) => state && isNaN(state) && state.length <= 3

    buildFormattedAddress = (addressParts) => {
        if (!(addressParts.streetName &&
            addressParts.localityName &&
            this.isStateValid(addressParts.state) &&
            this.isPostcodeValid(addressParts.postcode))) {
            return ''
        }

        return (`${addressParts.unitNumber || ''} ` +
            `${addressParts.streetNumber || ''} ${addressParts.streetName || ''} ${addressParts.streetType || ''}`.trim() +
            ', ' +
            `${addressParts.localityName || ''} ${(addressParts.state || '').toUpperCase()} ${addressParts.postcode || ''}`
        ).trim()
    }

    updateConsent = () => {
        this.setState({ contactConsent: !this.state.contactConsent })
    }

    handleSubmit = async () => {
        const { contactNumber, address } = this.state.formValues

        try {
            await tuoClient.setCustomer()
        } catch (e) { }

        this.props.setParentData({ contactNumber: contactNumber.replace(/\s/g, ''), address })
        this.props.history.push(`/${getPathBaseFromVoucherType(this.state.voucherType)}/child-eligibility`)
    }

    isPhoneValid = (phone) => phone === '' || phone === undefined || /^0[2-478]([ ]?[0-9]){8}$/g.test(phone)

    isAddressValid = () => {
        return this.state.formValues.address &&
        this.state.formValues.address.state === 'NSW' &&
        this.state.formValues.address.formattedAddress
    }

    hasAddressError = () => this.state.contactConsent &&
        !this.isAddressValid()

    isValid = () => this.state.contactConsent &&
        this.state.formValues.address &&
        this.state.formValues.address.state === 'NSW' &&
        this.state.formValues.address.formattedAddress &&
        this.isPhoneValid(this.state.formValues.contactNumber)

    updateTuo = () => {
        try {
            const { contactNumber, address } = this.state.formValues
            tuoClient.updateToSaveCustomer({ contactNumber: contactNumber.replace(/\s/g, ''), address })
        } catch (e) {}
    }

    render () {
        if (this.state.loading) {
            return <Spinner/>
        }
        return <Section className="research-data contactDetailsPage" style={{ marginBottom: '50px' }} steps={{ current: 1, total: 3 }}>
            <h1 className="title is-2" style={{ marginBottom: '2.5rem' }}>Enter your contact details</h1>
            <div>
                <label htmlFor="name" className="label is-size-6">Your name <span key="name" className="required_color">*</span></label>
                <input className="input disabled-input" name="name" value={this.props.parent.name} disabled/>
            </div>
            <div>
                <InputWithLabelAndErrorMessage
                    name="contact-number"
                    label={<span>
                            Your contact number
                        <div className="is-italic is-size-7 label-description">This number will only be used if we need to contact you regarding this application</div>
                    </span> }
                    value={this.state.formValues.contactNumber}
                    errorMessage="Please enter a valid australian phone number with area code (e.g 02 1111 2222, 0412 234 567)"
                    showErrorMessage={!this.isPhoneValid(this.state.formValues.contactNumber)}
                    onChange={this.handleInputChange('contactNumber')}
                    onBlur={this.updateTuo}
                />
            </div>
            <div>
                <Address inputClasses="input" name="address"
                    apiKey={process.env.REACT_APP_NSWPOINT_V2_API_KEY}
                    value={this.state.formValues.address}
                    address={this.state.formValues.address}
                    onChange={this.handleAddressChange}
                    onBlur={this.updateTuo}
                    showErrors={ true }
                    hasError={ this.hasAddressError() }
                    apiState='NSW'
                    errorMessage={ {
                        autoSuggest: 'You must select or enter an address in NSW',
                        suburb: 'The suburb is wrong.',
                        state: `The ${getVoucherTypeDetails(this.state.voucherType).name} vouchers are only available to parents, guardians and carers living in NSW.`,
                        postcode: 'The postcode is wrong.',
                        addressLine: 'The street address is wrong.'
                    } }
                    voucherType={this.state.voucherType}
                    addressLabel='Your address'/>
            </div>
            <h2 className="title is-5">Parent/guardian/carer consent <span key="name" className="required_color">*</span></h2>
            <div className="columns is-mobile" style={{ marginBottom: '2rem' }}>
                <div className="column is-narrow">
                    <StyledCheckbox
                        checked={this.state.contactConsent}
                        name="contact-consent"
                        onChange={this.updateConsent}
                    />
                </div>
                <div className="column">
                    <div style={{ marginBottom: '1rem' }}>I confirm that I am the parent/guardian/carer of the
                        child(ren) being applied for.
                    </div>

                    <div>I understand that it is an offence under the Crimes Act 1900 (NSW) to provide false or
                        misleading information to a public authority.
                    </div>
                </div>
            </div>
            <div id="tuo-review"/>
            <div>
                <ActionButton disabled={!this.isValid()} className="is-primary" onClick={this.handleSubmit}
                    text='Continue'/>
            </div>
        </Section>
    }
}

export default connect('parent', actions)(ContactDetailsPage)
