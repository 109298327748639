import React from 'react'
import PropTypes from 'prop-types'
import './StyledRadioButton.scss'
import StyledRadioButton from './StyledRadioButton'

const RadioButtonYesNo = (props) =>
    <div name={props.name}>
        <label className="label">{props.label} <span key="name" className="required_color">*</span></label>
        <StyledRadioButton name={props.name + 'No'} text="No" value="No" checked={props.value === 'No' } onChange={(e) => props.onChange(e)} />
        <StyledRadioButton name={props.name + 'Yes'} text="Yes" value="Yes" checked={props.value === 'Yes' } onChange={(e) => props.onChange(e) } />
    </div>

RadioButtonYesNo.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export default RadioButtonYesNo
