import React from 'react'
import PropTypes from 'prop-types'
import ChildIdentityCheck from '../child-identity-check/ChildIdentityCheck'
import ActionButton from '../components/ActionButton'
import { connect } from 'unistore/react'
import PageComponent from '../components/PageComponent'
import CheckMark from '../icons/tick-white.svg'
import './ChildEligibilityPage.scss'
import ReactGA from 'react-ga'
import { getPathBaseFromVoucherType, getVoucherTypeFromPath, getVoucherTypeDetails, VoucherTypeCK } from '../VoucherTypeDetails'
import ProgressSection from '../layout/ProgressSection'
import moment from 'moment'

const childLimit = 8

export class ChildEligibilityPage extends PageComponent {
    constructor (props) {
        super(props)
        this.state = {
            isAdding: this.props.transactionContext.children.length < childLimit,
            voucherType: getVoucherTypeFromPath(this.props.location.pathname)
        }
    }

    handleAddChild = () => {
        this.setState({ isAdding: false })
        window.scrollTo(0, 0)
        ReactGA.set({ page: `${this.props.location.pathname}/add-child` })
        ReactGA.pageview(`${this.props.location.pathname}/add-child`)
    }

    render () {
        return <ProgressSection className="child-eligibility-page" style={{ marginBottom: '7rem' }} steps={{ current: 2, total: 3 }}>
            <h1 className="title is-5">Check the eligibility of your child</h1>
            {
                this.state.voucherType === VoucherTypeCK && process.env.REACT_APP_CK_SECOND_VOUCHER_INSTRUCTIONS_DATE &&
                    moment().isAfter(process.env.REACT_APP_CK_SECOND_VOUCHER_INSTRUCTIONS_DATE)
                    ? <>
                        <p style={{ marginBottom: '1.5rem' }}>
                            <span key="name" className="required_color">*</span> {' '} indicates a required field
                        </p>
                        <div className="instructionsck">
                            A child will be eligible if they:
                            <ul>
                                <li>haven't already redeemed the $100 Creative Kids voucher issued this year</li>
                                <li>are a NSW resident</li>
                                <li>are aged between 4.5 to 18 years</li>
                                <li>are enrolled in school (from Kindergarten to Year 12, including those who are home-schooled
                                    or enrolled in secondary school education at TAFE NSW)
                                </li>
                                <li>have a current Medicare card holder.</li>
                            </ul>
                        </div>
                    </>
                    : <div className="instructions">
                        To be eligible for {this.state.voucherType === VoucherTypeCK ? 'a' : 'an'} {getVoucherTypeDetails(this.state.voucherType).name} voucher, a child needs to be:
                        <ul>
                            <li>a NSW resident</li>
                            <li>between 4.5 to 18 years of age</li>
                            <li>enrolled in school (from Kindergarten to Year 12, including those who are home-schooled
                                or enrolled in secondary school education at TAFE NSW)
                            </li>
                            <li>a current Medicare card holder</li>
                        </ul>
                    </div>
            }
            {this.props.transactionContext.children.map((child, index) => <div key={index} className="info-box blue-box is-size-5">
                <img src={CheckMark} alt=""/>
                <div style={{ marginLeft: '0.25rem' }}>{((child.longNames && child.longNames.length > 0) ? child.longNames[0] : child.firstName) || 'Child'} is eligible</div>
            </div>)}

            {this.state.isAdding &&
            <ChildIdentityCheck onChange={this.handleAddChild} voucherType={this.state.voucherType} />
            }
            <br/>
            {this.state.isAdding && this.props.transactionContext.children.length > 0 &&
                <div>
                    <ActionButton
                        className="is-normal is-secondary"
                        text="Remove child"
                        onClick={() => this.setState({ isAdding: false })}
                    />
                </div>
            }
            {(!this.state.isAdding && this.props.transactionContext.children.length < childLimit) &&
            <ActionButton className="is-secondary" text="Add another child"
                onClick={() => this.setState({ isAdding: true })}/>
            }

            { !this.state.isAdding &&
                this.props.transactionContext.children.length > 0 && <div className="button-row">
                <ActionButton className="is-primary"
                    text="Continue"
                    onClick={() => this.props.history.push(`/${getPathBaseFromVoucherType(this.state.voucherType)}/research-data`)}
                />
            </div>}
        </ProgressSection>
    }
}

ChildEligibilityPage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    transactionContext: PropTypes.object.isRequired,
    parent: PropTypes.object.isRequired
}

export default connect('transactionContext,parent')(ChildEligibilityPage)
