import createStore from 'unistore'
import persistStore from 'unissist'
import sessionStorageAdapter from './components/SessionStorageAdapter'

export const store = createStore({
    transactionContext: {
        children: [],
        vouchers: []
    },

    parent: {
        name: '',
        contactNumber: '',
        email: '',
        address: {}
    }
})

const adaptor = sessionStorageAdapter()

persistStore(store, adaptor)
