export const languageOptions = [
    { label: 'English', value: 'English' },
    { label: 'Arabic', value: 'Arabic' },
    { label: 'Cantonese', value: 'Cantonese' },
    { label: 'Greek', value: 'Greek' },
    { label: 'Mandarin', value: 'Mandarin' },
    { label: 'Vietnamese', value: 'Vietnamese' },
    { label: 'Other', value: 'Other' }
]

export const genderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Other', value: 'Other' },
    { label: 'Prefer not to say', value: 'Prefer not to say' }
]

export const atsiOptions = [
    { label: 'No', value: 'No' },
    { label: 'Aboriginal', value: 'Aboriginal' },
    { label: 'Torres Strait Islander', value: 'Torres Strait Islander' },
    {
        label: 'Aboriginal and Torres Strait Islander',
        value: 'Aboriginal and Torres Strait Islander'
    },
    { label: 'Prefer not to say', value: 'Prefer not to say' }]

export const yesNoOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
    { label: 'Prefer not to say', value: 'Prefer not to say' }
]

export const yesNoBooleanOptions = [
    { label: 'Yes' },
    { label: 'No' }
]

export const disabilityOptions = yesNoOptions

export const creativeActivities = [
    { label: 'Aboriginal Cultural Knowledge Program' },
    { label: 'Advertising' },
    { label: 'Architecture' },
    { label: 'Augmented/Virtual reality' },
    { label: 'Choreography' },
    { label: 'Circus arts' },
    { label: 'Cultural Experience (Aboriginal and Culturally Diverse Communities)' },
    { label: 'Coding' },
    { label: 'Craft' },
    { label: 'Creative writing' },
    { label: 'Dance' },
    { label: 'Design' },
    { label: 'Drama' },
    { label: 'Drawing' },
    { label: 'Debating' },
    { label: 'Fashion design' },
    { label: 'Game design' },
    { label: 'Graphic design' },
    { label: 'Industrial design' },
    { label: 'Language(s)' },
    { label: 'Media arts' },
    { label: 'Multimedia' },
    { label: 'Music' },
    { label: 'Painting' },
    { label: 'Parkour' },
    { label: 'Photography' },
    { label: 'Poetry' },
    { label: 'Printmaking' },
    { label: 'Public art' },
    { label: 'Public speaking' },
    { label: 'Publishing' },
    { label: 'Puppetry' },
    { label: 'Radio' },
    { label: 'Robotics' },
    { label: 'Screen art' },
    { label: 'Sculpture' },
    { label: 'Singing' },
    { label: 'Sketching' },
    { label: 'Slam poetry' },
    { label: 'Spoken Word' },

    { label: 'Theatre' },
    { label: 'Other performing arts' },
    { label: 'Other visual arts' },
    { label: "Don't know yet", value: 'Don’t know yet' }
].map(item => ({ ...item, value: item.value || item.label }))

export const hoursOfParticipationOptions = [
    { label: '0-2' },
    { label: '2-5' },
    { label: '>5' }
].map(item => ({ ...item, value: item.value || item.label }))
